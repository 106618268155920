import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const AttractivePrices = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('attractive_price')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('attractive_price')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'xar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
              <h2 className="subtitle">بماذا تتميز شركة غيار ؟</h2>
              <p>
              تتميز شركة غيار بأنها توفر جميع أنواع قطع الغيار؛ لجميع أنواع السيارات، وتملك شركة غيار موقعًا إلكترونيًا: www.ghayar.ae مع تطبيقات ذكيةghayar : (غيار)؛ تم تصميمها وفق أفضل الممارسات العالمية مما يسهل على المتعامل الحصول على القطع بأسهل الطرق؛ مع خدمة توصيل سريعة وآمنة، وتتم إدارة مبيعات الشركة من خلال مركز العمليات في إمارة دبي؛ الذي يسعى إلى تقديم العناية التامة بالمتعاملين، وتلتزم شركة غيار بسياسات واضحة لضمان القطع مع مرونة في الإرجاع.
              </p>
              <h2 className="subtitle">لماذا يجب علي تسجيل الدخول قبل شراء قطع الغيار؟</h2>
              <p>
              تقوم شركة غيار بمتابعة طلبات عملائها منذ القيام بعمليات الشراء وصولًا إلى التسليم وتقديم خدمات متميزة لما بعد البيع؛ وعليه، لا يمكننا القيام بذلك دون حفظ بيانات المتعامل الرئيسية التي تتم من خلال تسجيل الدخول، والتي تساعدنا كثيرًا على فهم رغبات المتعاملين واحتياجاتهم حتى نقوم بتوفيرها في أسرع وقت ممكن.
              </p>
            </div>:
            <div className="container"> 
                <h3>Attractive Price</h3>
                <p>‘Ghayar’is always dedicated and responsible to supplying the original parts &amp; accessories to the customer the best and competitive price than the market. In fact, we're confident that you won't find any car parts anywhere less than the ‘Ghayar’ website price.
                Many people would have to believe that to get the best parts for your car, you have to pay the highest prices - but we are different than others.<br />
                Here at ghayar.ae, we supply quality parts and accessories at lowest prices. As part of our Price Match Promise, we guarantee that we'll beat or match the price of any product you find for less elsewhere.</p>
                <b><u>Rules of the Price Match Promise</u></b>
                <p>In order to take advantage of this promise, the product must adhere to the following criteria</p>
                • It must be the same brand<br />
                • It must have exactly the same part number<br />
                • It must be brand new<br />
                • It must be in stock and ready for shipping<br />
                • Its total price must exclude shipping costs and handling fees<br />
                • It must be advertised at the lower price you were quoted<br />
                <br /><br />
                <b><u>How to take advantage of the Price Match Promise</u></b>
                <p>If you do find the same product for less anywhere else on the web, please make a note of the brand, part number, and the website on which it is advertised. Please then call us on 800 442522 or email us at contact@ghayar.ae</p>
                <p>We try our level best to match the price of every product. However, we reserve the right not to do so under certain conditions.</p>
                </div>
          
            }
          </div>
         
        </main>
          )
}

export default AttractivePrices;