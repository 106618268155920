import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const EasyWay = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('easy_way_to_shop')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('easy_way_to_shop')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'xar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
              <h2 className="subtitle">بماذا تتميز شركة غيار ؟</h2>
              <p>
              تتميز شركة غيار بأنها توفر جميع أنواع قطع الغيار؛ لجميع أنواع السيارات، وتملك شركة غيار موقعًا إلكترونيًا: www.ghayar.ae مع تطبيقات ذكيةghayar : (غيار)؛ تم تصميمها وفق أفضل الممارسات العالمية مما يسهل على المتعامل الحصول على القطع بأسهل الطرق؛ مع خدمة توصيل سريعة وآمنة، وتتم إدارة مبيعات الشركة من خلال مركز العمليات في إمارة دبي؛ الذي يسعى إلى تقديم العناية التامة بالمتعاملين، وتلتزم شركة غيار بسياسات واضحة لضمان القطع مع مرونة في الإرجاع.
              </p>
              <h2 className="subtitle">لماذا يجب علي تسجيل الدخول قبل شراء قطع الغيار؟</h2>
              <p>
              تقوم شركة غيار بمتابعة طلبات عملائها منذ القيام بعمليات الشراء وصولًا إلى التسليم وتقديم خدمات متميزة لما بعد البيع؛ وعليه، لا يمكننا القيام بذلك دون حفظ بيانات المتعامل الرئيسية التي تتم من خلال تسجيل الدخول، والتي تساعدنا كثيرًا على فهم رغبات المتعاملين واحتياجاتهم حتى نقوم بتوفيرها في أسرع وقت ممكن.
              </p>
            </div>:
            <div className="container">
               
                <p>Once you have decided on your parts, getting them couldn't be easier. With new ways to shop, we’ve made shopping as easy as possible for you with three great ways to order.</p>
                <h4 class="post__title">You Can Now:</h4>
                <ul>
                <li>Shop online available 24 hours, 7 days a week, 365 days a year</li>
                <li>Order by phone through our UAE based call center- 800 442522</li>
                <li>Mail your enquiries to contact@ghayar.ae</li>
                </ul>
                <h4 class="post__title">Delivery</h4>
                <p>Shop online at www.ghayar.ae 24/7 and choose the delivery option to suit you.</p>
                <p>
                </p><center><img src="https://ghayar.ae/assets/images/all-methods.png" /></center>
                <p></p>
                <p>All online purchases must be paid for using a credit / debit card.</p>
                <p>All prices include VAT and exclude customs duty and delivery charge.</p>
                <h4 class="post__title">More about our delivery options</h4>
                <h4 class="post__title">Order by Phone</h4>
                <p>
                You can contact our Mail Order Sales Line to speak to one of our sales advisors, to order simply indicate the required part by quoting the part number shown under the product description. The team can also confirm stock availability and give friendly and impartial advice.
                </p>
                <h4 class="post__title">Customer Support</h4>
                <p>
                Call our nationwide call center on 800 442522
                </p>
                <p>
                Mail to contact@ghayar.ae
                </p>
                <p>
                Customer Service lines are open:
                </p>
                <p>
                Saturday to Thursday 8.00 to 19.00
                </p>
            </div>
          
            }
          </div>
         
        </main>
          )
}

export default EasyWay;