import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { CartProcess, GetCarts } from "../../Helper/Carts";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import { ConfigContext } from "../../Context/ConfigContext";
import Swal from "sweetalert2";
import withRouter from "../../Routes/withRouter";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import Invoice from "../../Components/Invoice/Invoice";
import { jsPDF } from "jspdf";
import { withTranslation } from 'react-i18next';
class Success extends Component{
    static contextType = ConfigContext;
    
    constructor(props){
        super(props);
        this.state = {
            orderInfo : [],
            success: true
        };
        
    }
    componentDidMount(){
        this.OrderInfo();
    }
    OrderInfo = async() => {
        const { apiURL, token, lang } = this.context;
       
        try{
            const response = await axios.get(
                `${apiURL}/Account/GetMyOrderInfo`,{
                    params: {
                    lang: lang,
                    customer_id: localStorage.getItem('customer_id') || 1,
                    order_uid: this.props.params.order_uid,
                    },
                    headers : {
                        token: token,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      }
                }
            );
            console.log(token,response.data)
            this.setState({orderInfo: response.data.data, success: response.data.success})
            
            
        }catch(error){
            console.log('error',error)
        }
    }
    downloadInvoice = () => {
        var uid = this.props.params.order_uid;
        htmlToImage.toPng(document.getElementById('myInvoice'))
            .then(async (dataUrl) => {
                // console.log(`${dataUrl}`)
                // var link = document.createElement('a');
                // link.download = `Invoice-${uid}.pdf`;
                // link.href = dataUrl.replaceAll('data:image/png;', 'data:application/pdf;');
                // link.click();
                this.downloadPdf(dataUrl);
                
                // download(dataUrl, 'my-node.png');
            });
    }
    printInvoice = () => {
        var uid = this.props.params.order_uid;
        htmlToImage.toPng(document.getElementById('myInvoice'))
            .then(async (dataUrl) => {
                // console.log(`${dataUrl}`)
                // var link = document.createElement('a');
                // link.download = `Invoice-${uid}.pdf`;
                // link.href = dataUrl.replaceAll('data:image/png;', 'data:application/pdf;');
                // link.click();
                this.printPdf(dataUrl);
                
                // download(dataUrl, 'my-node.png');
            });
    }
    downloadPdf = async(base64Image) => {
    
        // A4 dimensions in points (1 inch = 72 points)
        const a4Width = 595.28;
        const a4Height = 841.89;
    
        const pdf = new jsPDF('p', 'pt', 'a4');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
    
        const image = new Image();
        image.src = base64Image;
    
        image.onload = () => {
          // Calculate the aspect ratio to maintain the image's proportions
          const aspectRatio = image.width / image.height;
    
          // Calculate the dimensions to fit the image within A4 without stretching
          let newWidth, newHeight;
          if (aspectRatio > 1) {
            newWidth = Math.min(a4Width, image.width);
            newHeight = newWidth / aspectRatio;
          } else {
            newHeight = Math.min(a4Height, image.height);
            newWidth = newHeight * aspectRatio;
          }
    
          // Set the canvas dimensions
          canvas.width = newWidth;
          canvas.height = newHeight;
    
          // Draw the image onto the canvas
          context.drawImage(image, 0, 0, newWidth, newHeight);
    
          // Convert the canvas content to a data URL (base64)
          const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
    
          // Add the image to the PDF
          pdf.addImage(dataUrl, 'JPEG', 0, 0, a4Width, a4Height);
        //   pdf.save('test.pdf');
            var title = 'Invoice-'+this.props.params.order_uid;
            pdf.save(title+'test.pdf');
          return pdf;
        };
      };
      printPdf = async( base64Image) => {
        const a4Width = 595.28;
        const a4Height = 841.89;
    
        const pdf = new jsPDF('p', 'pt', 'a4');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
    
        const image = new Image();
        image.src = base64Image;
    
        image.onload = () => {
          // Calculate the aspect ratio to maintain the image's proportions
          const aspectRatio = image.width / image.height;
    
          // Calculate the dimensions to fit the image within A4 without stretching
          let newWidth, newHeight;
          if (aspectRatio > 1) {
            newWidth = Math.min(a4Width, image.width);
            newHeight = newWidth / aspectRatio;
          } else {
            newHeight = Math.min(a4Height, image.height);
            newWidth = newHeight * aspectRatio;
          }
    
          // Set the canvas dimensions
          canvas.width = newWidth;
          canvas.height = newHeight;
    
          // Draw the image onto the canvas
          context.drawImage(image, 0, 0, newWidth, newHeight);
    
          // Convert the canvas content to a data URL (base64)
          const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
    
          // Add the image to the PDF
          pdf.addImage(dataUrl, 'JPEG', 0, 0, a4Width, a4Height);
        var title = 'Invoice-'+this.props.params.order_uid;
        const printWindow = window.open(pdf.output('bloburl'), '_blank');
        if (printWindow) {
          printWindow.document.title = title;
          printWindow.print();
        }
      }
    }
    render () {
        const { t } = this.props;
        const dir = localStorage.getItem('dir');
        return  (
        <main className="main" dir={dir}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div style={{marginTop: '10%'}}>
                        <center>
                        <p>
                            <i style={{fontSize: '80px', color: '#28a745'}} className="sicon-check"></i>
                        </p>
                        <h3>{t("thank_you_for_your_order")}</h3>
                        <p>{t("the_order_confirmation_line")}</p>
                        <button className="btn btn-default btn-ellipse btn-md">{t("order_number", {value: ":#"+this.props.params.order_uid})}</button>
                        </center>
                        <div className="d-flex justify-content-center" style={{marginTop: '10px'}}>
                            <button type="button" onClick={()=>this.downloadInvoice()} className="btn btn-success btn-sm" ><i className="sicon-cloud-download"></i></button>
                            <button type="button" onClick={()=>this.printInvoice()} className="btn btn-primary btn-sm" ><i className="sicon-printer"></i></button>
                            <button className="btn btn-info btn-sm" ><i className="sicon-envelope"></i></button>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        
                        <div id="myInvoice">
                                <Invoice order={this.state.orderInfo} />
                        </div>
                    </div>
                </div>
            </div>
        </main>
        );
    }
}

export default withRouter(withTranslation()(Success));