import React , {Component, useContext, useEffect} from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import { NavLink } from "react-router-dom";
const Aboutus = () =>{ 
  const {apiURL} = useContext(ConfigContext)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
        return(
        <main className="main about">
        <div className="page-header page-header-bg text-left" style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
          <div className="container">
            <h1><span>ABOUT US</span>
              OUR COMPANY</h1>
            <NavLink to={() => {}} className="btn btn-dark">Contact</NavLink>
          </div>{/* End .container */}
        </div>{/* End .page-header */}
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">About Us</li>
            </ol>
          </div>{/* End .container */}
        </nav>
        <div className="about-section">
          <div className="container">
            <h2 className="subtitle">Who We Are</h2>
            <p >E-COMMERCE
                is a limited liability company (LLC) registered in the Emirate of Dubai in the UAE
                ,a member of Dubai SME No. (8258), 
                and a member of the National Program No. (DUB003385).
            </p>
            <p> deals in e-commerce by providing spare parts to all
                 types of vehicles with quick and safe delivery service. 
                 Besides its commitment to global policies guaranteeing 
                the spare parts and return flexibility to achieve total
                customer satisfaction
             </p>
            <p>
                 cares to build up constructive and sustainable relationship with its clients
                 to support sales operations contributive to local economy growth and grants 
                 its suppliers sliver membership upgradable to gold membership when they render
                  competitive prices with 6 discounts offers during the year to be strategic partners,
                   while their products will receive sales priority therefor. This will yield greater revenues,
                    growth, and advantage to cooperative companies.
            </p>
          </div>{/* End .container */}
        </div>{/* End .about-section */}
        <div className="features-section bg-gray">
          <div className="container">
            <h2 className="subtitle">WHY CHOOSE US</h2>
            <div className="row">
              <div className="col-lg-4">
                <div className="feature-box bg-white">
                  <i className="far fa-question-circle" />
                  <div className="feature-box-content p-0">
                    <h3>Why Us</h3>
                    <p>
                     endeavors to meet whatever the clients demand with high quality,
                     competitive prices and best after sales services. Moreover,
                      key clients will have reward points program for regular purchase;
                       along with special discounts to increase sales and broaden its client’s 
                       scope from companies to public institutions and individuals to cover a 
                       biggest possible number of affiliate clients
                    </p>
                  </div>{/* End .feature-box-content */}
                </div>{/* End .feature-box */}
              </div>{/* End .col-lg-4 */}
              <div className="col-lg-4">
                <div className="feature-box bg-white">
                  <i className="fas fa-signal" />
                  <div className="feature-box-content p-0">
                    <h3>How do we operate</h3>
                    <p>
                         provides its clients with multiple options to obtain the spare parts 
                        of their choice easily through either its website, www..ae or  App.
                            Which, both display the images of the parts along with detailed information 
                            and prices
                    </p>
                  </div>{/* End .feature-box-content */}
                </div>{/* End .feature-box */}
              </div>{/* End .col-lg-4 */}
              <div className="col-lg-4">
                <div className="feature-box bg-white">
                  <i className="icon-online-support" />
                  <div className="feature-box-content p-0">
                    <h3>Safe Shopping</h3>
                    <p>
                        Here at  portal company take your online security very seriously. 
                        We use high-level 128-bit encryption technology, 
                        the most advanced security software currently available for online transactions on our website.
                        Rest assured that we take the privacy and security of your personal and payment details very seriously.
                    </p>
                  </div>{/* End .feature-box-content */}
                </div>{/* End .feature-box */}
              </div>{/* End .col-lg-4 */}
            </div>{/* End .row */}
          </div>{/* End .container */}
        </div>{/* End .features-section */}
        <div className="testimonials-section">
          <div className="container">
            <h2 className="subtitle text-center">HAPPY CLIENTS</h2>
            <div className="testimonials-carousel owl-carousel owl-theme images-left" data-owl-options="{
						'margin': 20,
                        'lazyLoad': true,
                        'autoHeight': true,
                        'dots': false,
                        'responsive': {
                            '0': {
                                'items': 1
                            },
                            '992': {
                                'items': 2
                            }
                        }
                    }">
              <div className="testimonial">
                <div className="testimonial-owner">
                  <figure>
                    <img src={`${apiURL}/public/placeholder_category.jpeg`} alt="client" />
                  </figure>
                  <div>
                    <strong className="testimonial-title">Ahmad S</strong>
                    <span>TurboDrive Autos CEO</span>
                  </div>
                </div>{/* End .testimonial-owner */}
                <blockquote>
                  <p> is a game-changer in the world of auto parts. 
                    Their extensive selection, quality products, 
                    and swift delivery make them my go-to for all my car needs. 
                    </p>
                </blockquote>
              </div>{/* End .testimonial */}
              <div className="testimonial">
                <div className="testimonial-owner">
                  <figure>
                    <img src={`${apiURL}/public/placeholder_category.jpeg`} alt="client" />
                  </figure>
                  <div>
                    <strong className="testimonial-title">Hala M</strong>
                    <span>DriveMaster Co CEO</span>
                  </div>
                </div>{/* End .testimonial-owner */}
                <blockquote>
                  <p>Exceptional service and a vast range of genuine car parts.  has simplified the way I shop for automotive components, making it a seamless experience every time. </p>
                </blockquote>
              </div>{/* End .testimonial */}
              <div className="testimonial">
                <div className="testimonial-owner">
                  <figure>
                    <img src={`${apiURL}/public/placeholder_category.jpeg`} alt="client" />
                  </figure>
                  <div>
                    <strong className="testimonial-title">Faisal A</strong>
                    <span>Swift Rides Inc CEO</span>
                  </div>
                </div>{/* End .testimonial-owner */}
                <blockquote>
                  <p> is a lifesaver for car enthusiasts like me. The ease of navigation, quick delivery, and top-notch customer service keep me coming back.</p>
                </blockquote>
              </div>{/* End .testimonial */}
            </div>{/* End .testimonials-slider */}
          </div>{/* End .container */}
        </div>{/* End .testimonials-section */}
        <div className="counters-section bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-6 col-md-4 count-container">
                <div className="count-wrapper">
                  <span className="count-to" data-from={0} data-to={200} data-speed={2000} data-refresh-interval={50}>200</span>+
                </div>{/* End .count-wrapper */}
                <h4 className="count-title">MILLION CUSTOMERS</h4>
              </div>{/* End .col-md-4 */}
              <div className="col-6 col-md-4 count-container">
                <div className="count-wrapper">
                  <span className="count-to" data-from={0} data-to={1800} data-speed={2000} data-refresh-interval={50}>1800</span>+
                </div>{/* End .count-wrapper */}
                <h4 className="count-title">TEAM MEMBERS</h4>
              </div>{/* End .col-md-4 */}
              <div className="col-6 col-md-4 count-container">
                <div className="count-wrapper line-height-1">
                  <span className="count-to" data-from={0} data-to={24} data-speed={2000} data-refresh-interval={50}>24</span><span>HR</span>
                </div>{/* End .count-wrapper */}
                <h4 className="count-title">SUPPORT AVAILABLE</h4>
              </div>{/* End .col-md-4 */}
              <div className="col-6 col-md-4 count-container">
                <div className="count-wrapper">
                  <span className="count-to" data-from={0} data-to={265} data-speed={2000} data-refresh-interval={50}>265</span>+
                </div>{/* End .count-wrapper */}
                <h4 className="count-title">SUPPORT AVAILABLE</h4>
              </div>{/* End .col-md-4 */}
              <div className="col-6 col-md-4 count-container">
                <div className="count-wrapper line-height-1">
                  <span className="count-to" data-from={0} data-to={99} data-speed={2000} data-refresh-interval={50}>99</span><span>%</span>
                </div>{/* End .count-wrapper */}
                <h4 className="count-title">SUPPORT AVAILABLE</h4>
              </div>{/* End .col-md-4 */}
            </div>{/* End .row */}
          </div>{/* End .container */}
        </div>{/* End .counters-section */}
      </main>
        )
    
}

export default Aboutus