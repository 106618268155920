import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const International = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('international_customers')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('international_customers')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'xar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
              <h2 className="subtitle">بماذا تتميز شركة غيار ؟</h2>
              <p>
              تتميز شركة غيار بأنها توفر جميع أنواع قطع الغيار؛ لجميع أنواع السيارات، وتملك شركة غيار موقعًا إلكترونيًا: www.ghayar.ae مع تطبيقات ذكيةghayar : (غيار)؛ تم تصميمها وفق أفضل الممارسات العالمية مما يسهل على المتعامل الحصول على القطع بأسهل الطرق؛ مع خدمة توصيل سريعة وآمنة، وتتم إدارة مبيعات الشركة من خلال مركز العمليات في إمارة دبي؛ الذي يسعى إلى تقديم العناية التامة بالمتعاملين، وتلتزم شركة غيار بسياسات واضحة لضمان القطع مع مرونة في الإرجاع.
              </p>
              <h2 className="subtitle">لماذا يجب علي تسجيل الدخول قبل شراء قطع الغيار؟</h2>
              <p>
              تقوم شركة غيار بمتابعة طلبات عملائها منذ القيام بعمليات الشراء وصولًا إلى التسليم وتقديم خدمات متميزة لما بعد البيع؛ وعليه، لا يمكننا القيام بذلك دون حفظ بيانات المتعامل الرئيسية التي تتم من خلال تسجيل الدخول، والتي تساعدنا كثيرًا على فهم رغبات المتعاملين واحتياجاتهم حتى نقوم بتوفيرها في أسرع وقت ممكن.
              </p>
            </div>:
            <div className="container">
                
                <h4 class="subtitle">If you are from outside UAE or your country isn't listed above, please don't worry as we can still help you!</h4>
                <p>Please select your parts and check out normally selecting your country; we'll then weigh your parcel and email you with the additional delivery charge. We can then proceed with your order once you have confirmed the additional charges.</p>
                <p>We endeavor to dispatch all international parcels using the most cost-effective methods, we'll use a number of couriers to find you the best deal.</p>
                <p>Alternatively, you can call our customer support team on 800442522 and they will be happy to assist you with your enquiry and quote the best price.</p>
                <h4 class="subtitle">Out of Area Delivery Charges.</h4>
                <p>Free delivery and Next day delivery services are not available for orders to International countries. A surcharge of customs dutyand VAT will be applied for deliveries to outside UAE. </p>
                <p>Some areas incur additional time for delivery and surcharge will be applicable.</p>
                <h4 class="subtitle">Oversized items</h4>
                <p>Due to the size of these parts, we employ specialist delivery service. We offer premium delivery for these parts and it takes minimum 5 working days depends upon the clearance from the exit port and items to get delivered. Unfortunately, there is no free delivery available for large exhaust parts and radiators &amp; other oversized items. Our basket automatically calculates best delivery price and transit time depending upon the delivery postcode. </p>
                <p><b>No deliveries will be made on Weekend holidays &amp;Public Holidays.</b> Delivery timescales will vary for items which are not marked as in-stock.</p>
                <h4 class="subtitle">Types of vehicles we provide spare parts for</h4>
                <p>Ghayar endeavors to provide all vehicles’ spare parts in cooperation with leading local and international companies</p>
                <h4 class="subtitle">Body Panels</h4>
                <p>Extra special care is taken with the handling of Body Panels. Each Body Panel is inspected and quality assured before they leave our warehouse.
                With this extra care, we utilize our special network of drivers to deliver the shipments to the customers. You can check if your area is covered by our delivery network by contacting our customer services on 800 442522 or email to contact@ghayar.ae
                No deliveries will be made on Weekend holidays &amp;Public Holidays.
                </p>
                <p>Delivery timescales will vary for items which are not marked as in-stock.</p>
                <h4 class="subtitle">Damaged Shipments</h4>
                <p>
                Upon receiving your order, inspect all shipping package(s) and items thoroughly for signs of damaged, missing, or incorrect parts. Our warehouses expert all efforts to prevent shipping damage, but it does occur on occasion. If you happen to receive a damaged part, please call us immediately to prevent you from being charged freight both ways. We are not responsible for damaged, missing, or incorrect parts after 30 business days, regardless of which party is at fault. We are also not responsible for lost or stolen packages and all claims from such incidents must be reported and processed through the shipping company. See Risk of Loss (below) for additional information.
                </p>
                <h4 class="subtitle">Risk of Loss</h4>
                <p>
                All items purchased from our website are made pursuant to our shipping terms, which are FOB shipping point. This means that risk of loss and title of any item is passed to you at the shipping point, which is when the carrier accepts the goods for transport. We are not directly responsible for lost or stolen packages, but we will be happy to assist you in filing a claim with the shipping company. You must contact us immediately if the entire shipment was not received within 7 business days of the carrier’s estimated delivery date. All requests must be made within thirty (30) days of the shipment date for any possibility of a claim.</p>
                <h4 class="subtitle">Rest of World</h4>
                <p>If you are outside from UAE or your country isn't listed above, please don't worry as we can still help you!</p>
                <p>Please select your parts and check out normally selecting your country; we'll then weigh your parcel and email you with the additional delivery charge. We can then proceed with your order once you have confirmed the additional charges.</p>
                <p>We endeavor to dispatch all international parcels using the most cost-effective methods, we'll use a number of couriers to find you the best deal.</p>
                <p>Alternatively, you can call our customer support team- 800 442522 and they will be happy to help with your enquiry and quote the best price.</p>
            </div>
          
            }
          </div>
         
        </main>
          )
}

export default International;