import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../Context/ConfigContext";
import slugify from "slugify";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const OESection = ({ART_ID,STR_ID}) => {
    const [crosses, setCrosses] = useState();
    const {gdcURL, apiURL} = useContext(ConfigContext);
    const [loadingCrosses, setLoadingCrosses] = useState(false);
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const { t } = useTranslation();
    useEffect(() => {
       
        fetch(`${gdcURL}/Parts/GetPartCrossRef?lang=en&art_id=${ART_ID}&lang=${locale}`)
        .then((res) => res.json())
        .then((data) => {
            setCrosses(data.data);
            
            setLoadingCrosses(false)
        })
    }, [ART_ID])
    return (
        <div className="container">
            <div className="pb-5 mt-5" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'left'}}>
                <h4>{t('oe_numbers')}</h4>
                <div className="row">
                {
                    crosses && crosses.map((cross, index) => {
                        
                        var part_slug = slugify(`${cross.ART_PRODUCT_NAME}`.toLowerCase())+'-'+cross.ART_SUP_BRAND+'-'+slugify(`${cross.ART_ARTICLE_NR}`.toLowerCase())+'-'+STR_ID+'-'+cross.ART_SUP_ID+'-'+slugify(`${cross.ART_ID}`.toLowerCase());
                        if(cross.ARL_TYPE === "OENumber")
                        return <div className="col-md-3" key={`OENumber-${index}`}>
                            
                            <NavLink to={`/OE-Spare-Parts/Details/${part_slug}`}>{cross.ART_SUP_BRAND} - {cross.ART_ARTICLE_NR}</NavLink>
                            {/* <td><NavLink to={`/Spare-Parts/Details/${part_slug}`}>View</NavLink>{cross.ART_ARTICLE_NR}</td> */}
                        </div>
                    })
                }
            </div>
            </div>
        </div>
    )
}
export default OESection;