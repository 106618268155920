import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const Privacy = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('privacy')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('privacy')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'ar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
                <p dir="rtl">غيار للتعامل الالكتروني ذ.م.م هي شركة مسجلة في دولة الإمارات العربية المتحدة في إمارة دبي (ويشار إليها بـ
                "نحن" أو"لنا" أو"لدينا" )، وهي الشركة المالكة والمشغلة لموقع الويب: www.ghayar.ae (يشارإليه فيما بعد باسم الموقع) وتطبيق غيارللهاتف المتحرك (يشارإليه فيما بعد بالتطبيق، ويشارإليها مجتمعين بالبيانات).</p>
                <p>تعد المحافظة على أمان بياناتك ذات أهمية قصوى بالنسبة لنا ونحن ملتزمون بتطبيق معايير صارمة للحفاظ على سريتك وحماية معلوماتك الشخصية وحقوقك كمستهلك، ويوضح هذا الإشعار الأساس الذي سنقوم بموجبه بالتعامل مع بياناتك الشخصية بما في ذلك على سبيل المثال لا الحصر: بيانات الدفع وغيرها من البيانات الأخرى التي تقدمها لنا عند استخدامك للموقع أو التطبيق بغية الوصول إلى خدماتنا (المشار إليها فيما بعد بالخدمات)، يرجى قراءة ما يلي بعناية لفهم ممارستنا فيما يتعلق بالبيانات، وباستخدامك لخدماتنا فإنك توافق على التعامل مع البيانات بما يتفق مع سياسة الخصوصية هذه، وتعد الإشارة بضمير المخاطب (أو ما شابهه) إشارة إليك كفرد أو شخص اعتباري؛ حسب مقتضى الحال. </p>
                <h4 class="subtitle">المعلومات التي قد نقوم بجمعها منك ومن مصادر أخرى</h4>
                <p>قد نقوم بجمع ومعالجة البيانات التالية:</p>
                <ul dir="rtl">
                <li>المعلومات التي تقدمها لنا عن طريق تعبئة النماذج على صفحات وسائل التواصل الاجتماعي لدينا أو على موقعنا أو من خلال التطبيق الخاص بنا، ويتضمن ذلك المعلومات المقدمة في وقت التسجيل، أو الاشتراك في خدمتنا، أو شراء البضائع عبر موقعنا على شبكة الإنترنت أو عن طريق التطبيق، أو طلب خدمات أخرى. </li>
                <li>قد نطلب منك أيضًا معلومات عند الإبلاغ عن مشكلة في موقعنا أو التطبيق الخاص بنا.</li>
                <li>قد نطلب منك أيضًا إكمال الدراسات الاستقصائية التي نستخدمها لأغراض البحث، رغم أنك لست مضطرًا للرد عليها.</li>
                <li>تفاصيل المعاملات التي تقوم بها من خلال موقعنا على شبكة الإنترنت أو التطبيق ولتلبية طلباتك.</li>
                <li>تفاصيل زياراتك لموقعنا على الويب أو التطبيق بما في ذلك على سبيل المثال لا الحصر: بيانات حركة المرور، وبيانات الموقع والمدونات، وبيانات الاتصال الأخرى. </li>
                <li>نقوم أيضًا بجمع بيانات السلوك والتصفح منك لغرض تقديم تجربة تسوق متميزة أو مخصصة لك عبر الإنترنت.</li>
                <li>قد نقوم بجمع معلومات حول جهاز الكمبيوتر الخاص بك، بما في ذلك عند توفر عنوان IP الخاص بك ونظام التشغيل ونوع المستعرض؛ لإدارة النظام والإبلاغ عن المعلومات الإجمالية للمعلنين لدينا، وهذه بيانات إحصائية حول إجراءات وأنماط تصفح مستخدمينا، ولا تحدد هوية أي فرد. </li>
                <li>بيانات عامة ومُجمعة وديموغرافية وغير شخصية.
                </li>
                </ul>
                <h4 class="subtitle">استخدامات المعلومات</h4>
                <p>قد نستخدم البيانات التي نحتفظ بها بالطرق التالية:</p>
                <ul dir="rtl">
                <li>ضمان تقديم المحتوى من موقعنا على الويب أو التطبيق بأكثر الطرق فعالية لك ولجهاز الكمبيوتر الخاص بك أو هاتفك الذكي.</li>
                <li>لتزويدك بالمعلومات أو المنتجات أو الخدمات التي تطلبها منا أو التي نشعر أنها قد تهمك، حيث وافقت على أن يتم الاتصال بك لهذه الأغراض.</li>
                <li>تنفيذ التزاماتنا الناشئة عن أي عقود مبرمة بينك وبيننا؛ على سبيل المثال: نقوم بتمرير تفاصيل الاتصال الخاصة بك إلى شركة البريد السريع لدينا الذين قد يتصلون بك عبر البريد الإلكتروني أو الرسائل النصية أو مكالمة هاتفية؛ لتأكيد تسليم طلبك. </li>
                <li>يجوز لنا إخطار موردينا بالتفاصيل الخاصة بك لأي أغراض ضمان.</li>
                <li>للسماح لك بالمشاركة في الميزات التفاعلية لخدمتنا، عندما تختار القيام بذلك.
                </li>
                <li>لتعزيز تجربتك أثناء استخدام موقعنا أو التطبيق الخاص بنا.</li>
                <li>لإعلامك عن تغييرات لخدمتنا.</li>
                <li>إذا كنت أحد العملاء الحاليين، فسنقوم بالاتصال بك عن طريق البريد الإلكتروني أو الهاتف للحصول على معلومات حول السلع والخدمات التي نقدمها، ولن نتصل بك عن طريق البريد الإلكتروني إذا كنت قد ألغيت اشتراكك في قائمتنا البريدية.</li>
                <li>إذا كنت عميلًا جديدًا، فسوف نتصل بك فقط بالوسائل الإلكترونية؛ إذا كنت قد وافقت على ذلك.</li>
                <li>سواء كنت عميلًا جديدًا أو حاليًا، إذا وافقت على أن يتم الاتصال بك إلكترونيًا، فسنقوم بالاتصال بك وفقًا لموافقتك.</li>
                <li> قد نستخدم معلوماتك الشخصية للاتصال بك إذا كان هناك أي إشعارات عاجلة تتعلق بالسلامة أو استدعاء المنتج للاتصال بك حيث نعتقد بشكل معقول أن معالجة معلوماتك الشخصية ستمنع أو تقلل من أي ضرر شخصي لك، ومن مصلحتك الحيوية بالنسبة لنا استخدام معلوماتك الشخصية بهذه الطريقة.</li>
                </ul>
                <h6 class="text-info">روابط الطرف الثالث</h6>
                <p>قد تتضمن قوائم مواقع الويب أو الأسواق روابط لمواقع الطرف الثالث، والمكونات الإضافية والتطبيقات، وقد يؤدي النقر فوق هذه الروابط أو تمكين هذه الاتصالات إلى السماح لجهات خارجية بجمع أو مشاركة بيانات عنك، وبما أننا لا نتحكم في مواقع الأطراف الثالثة هذه؛ لا نتحمل مسؤولية بيانات الخصوصية الخاصة بهم، وعندما تغادر موقعنا، نشجعك على قراءة إشعار الخصوصية لكل موقع تزوره. </p>
                <h4 class="subtitle">ملفات تعريف الارتباط وإشعارات الويب</h4>
                <p>قد نحصل على معلومات حول استخدامك العام للإنترنت باستخدام ملف تعريف الارتباط المخزن على القرص الصلب لجهاز الكمبيوتر الخاص بك أو هاتفك الذكي، وتحتوي ملفات تعريف الارتباط على معلومات يتم نقلها إلى محرك الأقراص الثابتة بجهاز الكمبيوتر الخاص بك أو هاتفك الذكي؛ إنها تساعدنا على تحسين موقعنا وتطبيقنا، وتقديم خدمة أفضل، وأكثر تخصيصًا؛ إذ تمكننا من:</p>
                <ul dir="rtl">
                <li>تقدير حجم الجمهور ونمط الاستخدام.</li>
                <li>تخزين المعلومات الأساسية حول تفضيلاتك، ومن ثم نقوم بتخصيص موقعنا وتطبيقنا وفقًا لاهتماماتك الفردية.</li>
                <li>لتسريع عمليات البحث.</li>
                <li>التعرف عليك عند العودة إلى موقعنا.</li>
                </ul>
                <p>يمكنك رفض قبول ملفات تعريف الارتباط عن طريق تنشيط الإعداد على متصفحك والذي يسمح لك برفض إعداد ملفات تعريف الارتباط، ومع ذلك؛ إذا حددت هذا الإعداد؛ فقد لا تتمكن من الوصول إلى أجزاء معينة من موقعنا وتطبيقنا ما لم تضبط إعداد المستعرض الخاص بك بحيث يرفض ملفات تعريف الارتباط، سيصدر نظامنا ملفات تعريف الارتباط عند تسجيل الدخول إلى موقعنا أو تطبيقنا.</p>
                <p>يرجى ملاحظة أن المعلنين قد يستخدمون أيضًا ملفات تعريف الارتباط، التي لا نملك السيطرة عليها.
                يحفظ موقعنا وتطبيقنا محتويات سلة التسوق الخاصة بك عندما لا تكمل المعاملة، أو قمت بإنهاء موقع الويب أو التطبيق الخاص بنا دون تقديم طلب، فسيتذكر النظام العناصر التي أضفتها إلى سلتك خلال 24 ساعة، وسيرسل إليك رسالة بريد إلكتروني مع تذكير؛ نقوم بذلك للتأكد من أن تجربة التسوق خالية من المتاعب قدر الإمكان، وإذا كنت لا تريد تلقي رسائل البريد الإلكتروني هذه في المستقبل، فالرجاء النقر على رابط إلغاء الاشتراك أسفل البريد الإلكتروني الذي تلقيته.
                </p>
                <h4 class="subtitle">الكشف عن المعلومات الخاصة بك</h4>
                <p>لتوفير بعض الخدمات لك، قد نحتاج إلى مشاركة بياناتك الشخصية مع أطراف ثالثة.
                </p>
                <ul dir="rtl">
                <li>قد نكشف معلوماتك الشخصية إلى:</li>
                <li>مزودي الخدمة الموثوق بهم الذين يعملون نيابة عنا والذين يقدمون خدمات محددة؛ مثل: استضافة المواقع، وتحليلات الويب، والدردشة على الإنترنت وخدمة العملاء وتنفيذ الطلبات وتحليل البيانات - بما في ذلك تخصيص البيانات-، وتوفير البنية التحتية، وبيانات التسويق عبر البريد الإلكتروني، ومراجعة مواقع خدماتنا، وخدمات التدقيق، وغيرها من الخدمات؛ لتمكينها من تقديم خدمات مميزة لعملائنا.</li>
                <li>شركات التوصيل الخاصة بنا أو شركات البريد السريع التي تقوم بتوصيل طلباتك.</li>
                <li>مواقع الويب التابعة لنا التي قد تستخدم معلوماتك الشخصية بالطرق الموضحة في "كيفية استخدامنا لقسم المعلومات" أعلاه أو فيما يتعلق بالمنتجات والخدمات التي تكمل مجموعة منتجاتنا وخدماتنا. </li>
                <li>موردو الطرف الثالث الذين يديرون نظام الدفع الآمن ومعالجة بطاقات الائتمان الخاصة بنا (مثل PayPal أو Ingenico).</li>
                <li>في حالة قيامنا ببيع أو شراء أي عمل أو أصول، ففي هذه الحالة قد نكشف بياناتك الشخصية إلى البائع أو المشتري المحتمل لهذه الأعمال أو الأصول. </li>
                <li>إذا قامت شركة غيار للتعامل الالكتروني ببيع جميع أصولها بشكل كبير أو تم الحصول عليها من قبل طرف ثالث، ففي هذه الحالة ستكون البيانات الشخصية التي تحتفظ بها عن عملائها أحد الأصول المنقولة.</li>
                <li>إذا كنا ملزمين بالكشف عن بياناتك الشخصية أو مشاركتها من أجل الامتثال لأي التزام قانوني، أو من أجل إنفاذ أو تطبيق شروط الاستخدام الخاصة بنا أو شروط وأحكام العرض والاتفاقات الأخرى؛ أو لحماية حقوق أو ملكية أو سلامة شركة غيار للتعامل الالكتروني أو عملائنا أو غيرهم، ويتضمن ذلك تبادل المعلومات مع الشركات والمؤسسات الأخرى لأغراض الحماية من الاحتيال والحد من مخاطر الائتمان.
                </li>
                </ul>
                <h4 class="subtitle">التحويلات الدولية</h4>
                <p>من الضروري في بعض الأحيان أن نشارك بياناتك بعض مزودي الخدمة خارج دولة الإمارات العربية المتحدة؛ إذا حدث هذا، فسوف نضمن أن يكون النقل متوافقًا مع قوانين حماية البيانات ذات الصلة.</p>
                <p>تتمثل ممارستنا المعتادة في استخدام بنود تعاقدية قياسية معتمدة لمثل هذه التحويلات. </p>
                <h4 class="subtitle">كيف نحمي بياناتك؟</h4>
                <p>نحن ملتزمون بالحفاظ على بياناتك الشخصية آمنة ومأمونة واستخدام عدد من التدابير الأمنية مثل:</p>
                <ul dir="rtl">
                <li>نحن نضمن أن موقع الويب والتطبيق الخاص بنا والبيانات مدعومة بتقنية TLS 1.2 باستخدام معيار أمان RSA 2048 bit / أو معايير أخرى مناسبة من وقت لآخر .</li>
                <li>مراقبة ومراجعة مقدمي خدماتنا لضمان حصولهم على مستوى مناسب من الحماية على النحو المطلوب بموجب PCI DSS.</li>
                <li>تتم جميع معاملات الدفع ببطاقات الائتمان والخصم على موقعنا وتطبيقنا عبر سلة التسوق عبر الإنترنت.</li>
                <li>يتم تخزين جميع المعلومات التي تقدمها لنا على خوادمنا الآمنة بالنسبة للمستخدمين المسجلين، حيث قدمنا لك (أو حيثما اخترت) كلمة مرور تمكنك من الوصول إلى أجزاء معينة من موقعنا وتطبيقنا، فأنت مسؤول عن الحفاظ على سرية كلمة المرور هذه، ونطلب منك عدم مشاركة كلمة المرور الخاصة بك مع أي شخص.</li>
                </ul>
                <p>نستخدم التدابير المعقولة والتنظيمية والفنية والإدارية لحماية المعلومات الشخصية الخاضعة لسيطرتنا؛ لسوء الحظ، نقل المعلومات عبر الإنترنت غير آمن تمامًا، وسوف نبذل قصارى جهدنا لحماية بياناتك الشخصية؛ وسنستخدم إجراءات احترازية صارمة وميزات أمان لمحاولة منع الوصول غير المصرح به إلى تلك البيانات. </p>
                <h4 class="subtitle">الأساس القانوني لمعالجة البيانات الشخصية الخاصة بك</h4>
                <p>تتم معالجة البيانات الشخصية التي تقدمها إلينا من أجل شراء البضائع وغيرها من البيانات الشخصية التي تم إنشاؤها لاتفاقيات المعاملات لأنها ضرورية لأداء العقد معك.
                </p>
                <p>تتم معالجة جميع البيانات الشخصية الأخرى لمصالحنا المشروعة (كما هو موضح أدناه) والامتثال لالتزاماتنا القانونية،</p>
                <p>بشكل عام، نحن نعتمد فقط على موافقة التقيد كأساس قانوني للاتصال (والسماح لأطراف ثالثة مختارة بالاتصال) بالعملاء الجدد بالوسائل الإلكترونية و / أو إرسال الاتصالات التسويقية المباشرة عبر البريد الإلكتروني أو الرسائل النصية للعملاء الجدد.
                لديك الحق في سحب موافقتك في أي وقت.
                </p>
                <h4 class="subtitle">مصالحنا المشروعة</h4>
                <p>الأساس القانوني لمعالجة بيانات العميل ضروري لمصالحنا المشروعة بما في ذلك: </p>
                <ul dir="rtl">
                <li>بيع وتوريد السلع والخدمات لعملائنا.</li>
                <li>الترويج والتسويق والإعلان عن منتجاتنا وخدماتنا.</li>
                <li>إرسال الاتصالات الترويجية ذات الصلة والمصممة للعملاء الأفراد. </li>
                <li>فهم سلوك عملائنا وأنشطتهم وتفضيلاتهم واحتياجاتهم. </li>
                <li>التعامل مع جهات اتصال العملاء والاستفسارات والشكاوى أو المنازعات.</li>
                <li>إدارة مطالبات التأمين من قبل العملاء.</li>
                <li>الوفاء بواجباتنا تجاه عملائنا وزملائنا والمساهمين وأصحاب المصلحة الآخرين.</li>
                <li>حمايتنا وموظفينا وعملائنا، من خلال اتخاذ الإجراءات القانونية المناسبة ضد الأطراف التي ترتكب أعمالًا إجرامية أو تنتهك الالتزامات القانونية تجاهنا.</li>
                <li>الامتثال لالتزاماتنا القانونية والتنظيمية، والتعامل الفعال مع أي مطالبات قانونية أو إجراءات إنفاذ تنظيمية متخذة ضدنا. </li>
                </ul>
                <h4 class="subtitle">حقوقك المشروعة</h4>
                <p>في أي وقت نمتلك فيه بياناتك الشخصية أو نقوم بمعالجتها، تتمتع بالحقوق التالية:</p>
                <ul dir="rtl">
                <li>لك الحق في طلب نسخة من المعلومات التي نحتفظ بها عنك.</li>
                <li>لديك الحق في تصحيح البيانات التي نحتفظ بها عنك والتي تكون غير دقيقة أو غير كاملة.</li>
                <li>يمكنك طلب حذف البيانات التي نحتفظ بها عنك من سجلاتنا.</li>
                <li>لديك الحق في الاعتراض على أنواع معينة من المعالجة مثل التسويق المباشر.</li>
                </ul>
                <p>سيتم إعادة توجيه جميع الطلبات المذكورة أعلاه في حالة وجود طرف ثالث مشترك في معالجة بياناتك الشخصية.</p>
                <p>يمكنك أيضًا ممارسة هذا الحق في أي وقت عن طريق الاتصال بنا على شركة غيار للتعامل الالكتروني بأي من الوسائل الموضحة أدناه. </p>
                <p>قد يحتوي موقعنا الإلكتروني أو تطبيقنا؛ من وقت لآخر، على روابط من وإلى مواقع شبكاتنا الشريكة والمعلنين والشركات التابعة لنا؛ إذا اتبعت رابطًا لأي من هذه المواقع، فيرجى ملاحظة أن هذه المواقع لديها سياسات خصوصية خاصة بها ولا نتحمل أي مسؤولية أو التزام تجاه هذه السياسات، ويرجى التحقق من هذه السياسات قبل إرسال أي بيانات شخصية إلى هذه المواقع.</p>
                <h4 class="subtitle">كم من الوقت نحافظ على بياناتك؟</h4>
                <p>سنحتفظ فقط ببياناتك الشخصية طالما كان ذلك ضروريًا لتحقيق الأغراض التي جمعناها من أجلها، بما في ذلك لأغراض تلبية أي متطلبات قانونية أو محاسبية أو إبلاغ. </p>
                <p>تتمثل إجراءاتنا لإدارة الاحتفاظ بالبيانات في الاحتفاظ ببيانات العميل لمدة 7 سنوات.</p>
                <p>ستعمل وظيفة إلغاء الاشتراك في التسويق عبر البريد الإلكتروني على إزالة التفاصيل الخاصة بك من قوائم التسويق وسيتم إرسال تأكيد الإزالة إلى عنوان بريدك الإلكتروني.</p>
                <p>يمكن أن يستغرق النسخ الاحتياطي للبيانات فترة تصل إلى 60 يومًا لإزالة بيانات محددة من النظام.</p>
                <h4 class="subtitle">تغييرات على إشعار الخصوصية لدينا</h4>
                <p>أي تغييرات قد نجريها على إشعار الخصوصية في المستقبل سيتم نشرها على صفحة الموقع أو التطبيق، وعند الاقتضاء، سيتم إخطارك بالبريد الإلكتروني. </p>
                <h4 class="subtitle">اتصل بنا</h4>
                <p>إذا كانت لديك أي أسئلة حول السياسة أعلاه التي لم تتم الإجابة عليها، فيرجى الاتصال بنا بأي من الوسائل التالية:</p>
                <ul dir="rtl">
                <li> الاتصال بنا عبر هاتف الشركة: 97142551383 +</li>
                <li>أو الهاتف المجاني :800442522</li>
                <li> أو الموبايل: 971559117222+</li>
                <li>مراسلتنا عبر البريد الإلكتروني:<a href="mailto:contact@ghayar.ae">contact@ghayar.ae</a></li>
                </ul>
                <span class="text-right d-block">تاريخ الإصدار: [15 مارس 2020]</span>

            </div>:
            <div className="container">
                
                <p>Ghayar e Dealing is a limited liability company (L.L.C) registered in the Emirate of Dubai, UAE, referred to herein as 'We', 'Our', or 'Ours'. Ghayar owns and operates the website: www.ghayar.ae (herein referred to as the 'Website' and the Ghayar App (herein referred to as the 'App') which both referred to collectively as 'Data'.</p>
                <p>Protecting your data is our top priority as per our strict standards to protect your rights as a consumer. This notice clarifies the way we deal with your personal data including but not limited to payment and other information provided while using our Website or App to reach service (herein referred to as Service). Please read the following carefully to understand our practices regarding data. As using our services, you agree to dealing with data in compliance with our privacy policy. The second person pronoun (or the like) is a signal of address to you as a person or legal person as the case may be. </p>
                <h4 class="subtitle">Information we collect about you and other sources</h4>
                <p>We may collect and process the following data: </p>
                <ul>
                <li>Information you provide us with through filling of forms on our social network pages, i.e. our Website or App. Including the information presented while registering or taking part in our Service, purchasing on our Website or App or any other service.</li>
                <li>We may also ask for information when informed to troubleshoot issues on our Website or App.</li>
                <li>We may also ask you to complete surveys we use for research, though you do not have to answer. </li>
                <li>Details of transactions you did through our Website or App</li>
                <li>Details of your visit to our Website or App, including but not limited to data regarding web traffic, blogs and other communications. </li>
                <li>Collecting data of your web surfing behavior for excellent shopping experience tailored for you on the net.</li>
                <li>We may collect data about your pc, including your IP address, operating system and browser type for system admin and our advertisers reporting on general information. As the data is statistical on our users’ procedures and patterns, it does not identify any individual’s identity. </li>
                <li>Impersonal general demographic aggregate data.
                </li>
                </ul>
                <h4 class="subtitle">Uses of information</h4>
                <p>We may use the reserved data in the following ways:.</p>
                <ul>
                <li>To guarantee providing the content from our Website or App in the most effective means to you through your computer or smart phone.</li>
                <li>To provide you with the information, products or services you request from us out of interest according to your consent to be contacted for such purposes</li>
                <li>To meet our commitments related to any agreements reached between you and us. For example, passing your contact details to our courier company to reach you through your e-mail, SMS or phone call to confirm delivery</li>
                <li>We may pass your details to our suppliers for any guarantee purposes.</li>
                <li>To allow you to take part in the interactive features of our services when you choose.
                </li>
                <li>To enhance your experience when using our Website or App.</li>
                <li>To inform you about possible changes to our services.</li>
                <li>To contact you if you are still a registered client through e-mail or phone for information about our commodities and services. There shall be no contact if you cancelled your respective subscription.</li>
                <li>As regards new clients, we only contact them through electronic means if they thus agree.</li>
                <li>We reach new and existing clients via electronic means upon their consent.</li>
                <li><to contact="" you="" in="" case="" of="" urgent="" notices="" regarding="" safety="" or="" product="" recall="" when="" we="" deem="" it="" reasonable="" that="" processing="" your="" personal="" information="" such="" a="" manner="" will="" vitally="" prevent="" lessen="" harm="" li="">
                </to></li></ul>
                <h6 class="text-info">Third Party Links</h6>
                <p>The Website may contain links to third parties and additional components and applications. A lick on such links may enable these links to allow external entities to collect or share your data. As we have no control over third party websites, we hereby bear no responsibility for their privacy data. Thus, we encourage you to read the privacy notice pertinent to any site you visit upon leaving our Website. </p>
                <h4 class="subtitle">Cookies and Web Notifications</h4>
                <p>We may obtain information on our general internet using stored on your computer hard drive or smartphone. Cookies contain information transformed to your computer hard drive or smartphone to help us improve our website applications and provide better specialist service to:</p>
                <ul>
                <li>Estimate number of public and usage pattern. </li>
                <li>Storage of basic information on your preferences to tailor our Website and App accordingly.</li>
                <li>Tune search speed.</li>
                <li>To identify you when revisiting our Website.</li>
                </ul>
                <p>You may decline cookies by modifying your browser settings; however thus, you may not be able to reach specific parts of our Website and App, unless you reset to allow our cookies issued the moment you surf our Website or App. Advertisers may use cookies over which we do not have control nevertheless.</p>
                <p>Our Website and App retrieve your shopping cart contents when you do not complete a transaction or when you shut down our website or app without notification. The system will recall the items within <a href="#">24 hours</a> you added to your cart and send you an e-mail message reminder to ensure hassle free shopping experience as possible. In the event you do not want to receive such e-mails in future, please click on the link under the e-mail you received to unsubscribe. </p>
                <h4 class="subtitle">Your Personal Information Disclosure </h4>
                <p>To provide you with some information, we may need to share your personal data with third parties. </p>
                <ul>
                <li>We may share your personal information with:</li>
                <li>Trusted service providers working on our behalf to provide specific services such as webhosting, web analyses, internet chatting, customer service, deliveries and data analysis. Including data allocation, providing infrastructure, shopping data via e-mail, reviewing our services websites, auditing services and others to enable them provide better services for our clients. </li>
                <li>Our delivery and courier companies that ship your orders.</li>
                <li>Our affiliated websites that may use your personal information with the ways explained in ‘how we use the information portion’ as stated above herein or regarding products and services that complete the group of our products and services. </li>
                <li>Third party suppliers who manage our safe pay system or credit cards such as (PayPal or Ingenico).</li>
                <li>We may share your personal information data to the potential seller or buyer in the event we sell or buy any business or assets. </li>
                <li>When Ghayar e Dealing sells most part of its assets or acquired by a third party, clients retrieved personal data shall be part of the movable assets. </li>
                <li>When we are obliged to share your personal information to abide by any legal commitment, execute, or meet our usage conditions or other display terms and agreements. Alternatively, to protect Ghayar e Dealing property and safety rights or rights of our clients etc. This may include exchange of information with other companies and institutions for protection against fraud and credit risk.
                </li>
                </ul>
                <h4 class="subtitle">International Transfers:</h4>
                <p>We may find it necessary some times to share your data with some service suppliers abroad. In such case, we will ensure that such transfer complies with the relative data protection laws. </p>
                <p>Our usual practice with regard to such transfers is based on using standard approved contractual terms.</p>
                <h4 class="subtitle">How we Protect your Data?</h4>
                <p>We are committed to keep your personal data safe through the following safety precautions:</p>
                <ul>
                <li>Our Website, App and data are supported by TLS 1.2 technology utilizing safety standard RSA 2048 bit or other suitable standard from time to time.</li>
                <li>Monitoring and reviewing our services providers to guarantee that they obtain appropriate necessary protection as per the PCI DSS. </li>
                <li>All payments for internet shopping cart go through credit and debit cards</li>
                <li>All information our registered clients provide us with is retrieved in our safe severs. The password we rendered (or as you chose) enables you to reach specific parts of our website and app. The password privacy is your own responsibility and do not share with any others.</li>
                </ul>
                <p>We use reasonable systematic, technical and administrative precautions to protect your personal information under system control. Though internet information traffic is not very safe, we will do our best to protect your personal data by using precautionary safety measures to prevent an authorized access to your data. </p>
                <h4 class="subtitle">Legal Basis for your Personal Data Processing</h4>
                <p>The personal information you provide us with when purchasing, is processed for the necessary agreement and transactions.
                </p>
                <p>Processing of other personal data is done in accordance with our legitimate interests and commitments (as stated herein below). </p>
                <p>In general, we rely on the abidance agreement as a legal basis for communication (and allow selected third parties to communicate) with new clients via SMS or e-mail. You may withdraw your consent at any time. </p>
                <h4 class="subtitle">Our Legitimate Interests:</h4>
                <p>The legal basis for client’s data processing is necessary for our legitimate interests; including:</p>
                <ul>
                <li>Selling and supplying commodities and services to our clients.</li>
                <li>Promoting, marketing and advertising our products and services.</li>
                <li>Sending of related promotions tailored for individual clients. </li>
                <li>Knowing our clients behavior, activities, preferences and needs.</li>
                <li>Dealing with clients contact entities, enquiries, complaints and disputes.</li>
                <li>Managing clients’ insurance claims.</li>
                <li>Meeting our duties toward our clients, colleagues, shareholders and other stakeholders</li>
                <li>Protecting our employees and clients by taking appropriate legal procedures against parties committing criminal actions or violating legal commitments.</li>
                <li>Abiding by our legal and organizational commitments and effectively dealing with any legal claims or organizational enforcement actions against us. </li>
                </ul>
                <h4 class="subtitle">Your Legitimate Rights:</h4>
                <p>Any time we have or process your personal data, you have the following rights:</p>
                <ul>
                <li>Asking for a copy of your information, we save.</li>
                <li>Correcting your inaccurate or incomplete data that we keep.</li>
                <li>Asking for deletion of your information in our records.</li>
                <li>Objecting to certain types of processing such as direct publicizing. </li>
                </ul>
                <p>In the event that there is a third party involved in processing your personal data, all the requests mentioned herein above shall be forwarded thereto. </p>
                <p>You may have the right stated by contacting Ghayar e Dealing via any of the down below means. </p>
                <p>Occasionally, our website or app may contain links from and to partner websites, advertisers and affiliated companies. In case you track any of these sites, please note that they have their own privacy policies for which we bear no responsibility or commitment. Please check these policies before submitting any personal data thereto. </p>
                <h4 class="subtitle">How Long We Keep Your Data. </h4>
                <p>We only keep your personal data as long as necessary to realize purposes for which we have collected. Including meeting any legal, accountancy or annunciation claims. </p>
                <p>Our procedures to keep client’s data run for seven years. </p>
                <p>When you unsubscribe from our Website or App via e-mail, your personal details shall be removed as a confirmation message is sent to you accordingly.</p>
                <p>The reserve copy of the data may take around 60 days to remove specific information from the system. </p>
                <h4 class="subtitle">Changes to Our Privacy Policy</h4>
                <p>Any changes regarding our privacy notices in the future shall be published on our Webpage or App. You shall be notified by e-mail when necessary. </p>
                <h4 class="subtitle">Contact Us</h4>
                <p>In case of queries not addressed hereinabove, please contact us through the following means.</p>
                <ul>
                <li>Company Tel: +97142551383</li>
                <li>Toll-free: 800442522</li>
                <li>Mob: +971559117222</li>
                <li>Email: <a href="mailto:contact@ghayar.ae">contact@ghayar.ae</a></li>
                </ul>
                
                </div>
           
          
            }
          </div>
         
        </main>
          )
}

export default Privacy;