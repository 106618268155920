import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const CarPartsOnline = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('car_parts_online')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('car_parts_online')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'xar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
              <h2 className="subtitle">بماذا تتميز شركة غيار ؟</h2>
              <p>
              تتميز شركة غيار بأنها توفر جميع أنواع قطع الغيار؛ لجميع أنواع السيارات، وتملك شركة غيار موقعًا إلكترونيًا: www.ghayar.ae مع تطبيقات ذكيةghayar : (غيار)؛ تم تصميمها وفق أفضل الممارسات العالمية مما يسهل على المتعامل الحصول على القطع بأسهل الطرق؛ مع خدمة توصيل سريعة وآمنة، وتتم إدارة مبيعات الشركة من خلال مركز العمليات في إمارة دبي؛ الذي يسعى إلى تقديم العناية التامة بالمتعاملين، وتلتزم شركة غيار بسياسات واضحة لضمان القطع مع مرونة في الإرجاع.
              </p>
              <h2 className="subtitle">لماذا يجب علي تسجيل الدخول قبل شراء قطع الغيار؟</h2>
              <p>
              تقوم شركة غيار بمتابعة طلبات عملائها منذ القيام بعمليات الشراء وصولًا إلى التسليم وتقديم خدمات متميزة لما بعد البيع؛ وعليه، لا يمكننا القيام بذلك دون حفظ بيانات المتعامل الرئيسية التي تتم من خلال تسجيل الدخول، والتي تساعدنا كثيرًا على فهم رغبات المتعاملين واحتياجاتهم حتى نقوم بتوفيرها في أسرع وقت ممكن.
              </p>
            </div>:
            <div className="container"> 
                    <h4 class="post__title">Car Parts Online</h4>
                    <p>‘Ghayar’ offers a massive choice of car parts for every make &amp; model at best prices with safe delivery. Our car spares range is biggest compared to any of the independent motor factor in the middle east. By reputation we are the leading car part supplier in the GCC today, so for great prices and top offers look no further. Browse now by car manufacturer and model to find the right parts for your car!</p>
                    <p>&nbsp;</p>
                    <p><strong><u>Brands</u></strong></p>
                    <ul>
                    <li><a href="#">Alfa Romeo Parts</a> Amaron&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Audi Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                    <li><a href="#">BMW Parts</a> BP Oil&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bosch</li>
                    <li><a href="#">Chevrolet Parts</a> <a href="#">Chrysler Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Citroen Parts</a></li>
                    <li><a href="#">Daewoo Parts</a> <a href="#">Daihatsu Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DeWalt&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                    <li><a href="#">Ford Parts</a> Makita&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Black&amp;Decker</li>
                    <li><a href="#">Honda Parts</a> <a href="#">Hyundai Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Jaguar Parts</a></li>
                    <li><a href="#">Jeep Parts</a> <a href="#">Kia Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Land Rover Parts</a></li>
                    <li><a href="#">Lexus Parts</a> <a href="#">Mazda Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Mercedes Parts</a></li>
                    <li><a href="#">Mini Parts</a> <a href="#">Mitsubishi Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Nissan Parts</a></li>
                    <li><a href="#">Peugeot Parts</a> <a href="#">Porsche Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Renault Parts</a></li>
                    <li><a href="#">Rover Parts</a> <a href="#">Saab Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Seat Parts</a></li>
                    <li><a href="#">Skoda Parts</a> <a href="#">Subaru Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#">Suzuki Parts</a></li>
                    <li><a href="#">Toyota Parts</a> Total Oil&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Varta</li>
                    <li><a href="#">Volkswagen Parts</a> <a href="#">Volvo Parts</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Uken</li>
                    <li>Hitachi Stanley&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3M</li>
                    </ul>
                    </div>
          
            }
          </div>
         
        </main>
          )
}

export default CarPartsOnline;