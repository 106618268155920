import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const Delivery = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('delivery_shipment')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('delivery_shipment')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'ar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
              <h2 className="subtitle">سياسة التوصيل داخل دولة الإمارات العربية المتحدة</h2>
                <p>تحرص شركة غيار على توصيل القطع المطلوبة داخل دولة الإمارات العربية المتحدة في اليوم التالي من الطلب.</p>
                 
                <h2 className="subtitle">سياسة الشحن إلى خارج الدولة</h2>
                <p>
                جميع الشحنات مغطاة بالكامل بالتأمين، وتبذل شركة غيار قصارى جهدها لضمان وصول الطرود إليك في حالة ممتازة، ونتعامل مع شركات معروفة جيدًا مثل FedEx و TNT و DHL و EMS وآرامكس و بريد الإمارات (للطرود المسجلة)، ولن نكون مسؤولون عن أي شحنات متأخرة، وجميع مواعيد الشحن تقريبية، وفي معظم الحالات نقوم بإرسال الطرود من يوم على ثلاثة أيام عمل، ولا يحق للمشتري الحصول على المبالغ المدفوعة أو تخفيضات للأسعار أو أي تعويضات أخرى نتيجة للتأخر في الشحن أو الفشل في التسليم في الوقت المقدر للتسليم.
                </p>
                <p>
                أنت توافق على عدم رفض أي شحنات لأي سبب كان، وسوف تخضع الشحنات المرفوضة لرسوم شحن إضافية قبل إرسال العناصر البديلة، وإذا كان هناك عيب مصنعي أو خطأ من شركة غيار فإنه يتم استلام العناصر وعدم رفضها، ثم يتم إرجاعها وفق سياسة الإرجاع الخاصة بنا، وستتحمل شركة غيار تكلفة الإرجاع.
                </p>
                <p>نحن نشحن إلى 180 دولة تقريبًا في قائمة سلاسل التوريد الخاصة بنا، وإذا كانت عناصر المخزون تتطلب صندوقًا خشبيًا للحفاظ عليها (مثل الزجاج الأمامي وزجاج الباب الجانبي)؛ فإنه يخضع لرسوم إضافية تتراوح ما بين 40-190 دولارًا أمريكيًا، ويعتمد ذلك على حجم الصندوق وبلد المقصد.</p>
                <h2 className="subtitle">القانون الحاكم / الولاية القضائية</h2>
                <p>
                تخضع مضمون هذه السياسة لقوانين دولة الإمارات العربية المتحدة إمارة دبي، بغض النظر عن الاختيار أو تضارب مبادئ القانون، وبهذا فأنت توافق على الولاية القضائية لدولة الإمارات العربية المتحدة لحل أي نزاع أو مطالبة أو خلاف يتعلق بالاتفاقات أو ما ينشأ بشأنها.
                </p>
                <h2 className="subtitle">اتصل بنا</h2>
                <p>إذا كانت لديك أي أسئلة حول السياسة أعلاه التي لم تتم الإجابة عليها، فيرجى الاتصال بنا بأي من الوسائل التالية:</p>

                <ul>
                    <li>الاتصال بنا عبر هاتف الشركة: 97142551383 +</li>
                    <li>أو الهاتف المجاني :800442522</li>
                    <li>أو الموبايل: 97155 760 0255+</li>
                    <li>مراسلتنا عبر البريد الإلكتروني:contact@ghayar.ae</li>
                </ul>
            </div>:
            <div className="container">
                <h2 className="subtitle">Delivery across UAE</h2>
                <p>Ghayar is keen to deliver the required parts within the United Arab Emirates on the next day of the order.</p>
                 
                <h2 className="subtitle">Shipping Abroad</h2>
                <p>
                    All shipments are insurance-covered. At Ghayar, we do our best to guarantee safe delivery of parcels through our dealing with reputable companies such as FedEx, TNT, DHL, and EMS, ARAMEX and Emirates Post for registered parcel services mail. However, as we deliver within 2-3 working days, all delivery times are approximate; we bear no responsibility for late delivery i.e. no refund, discount or any recompense for late delivery or failure thereof at a time previously estimated.
                </p>
                <p>
                    The customer agrees not to reject any shipment for whatever reason. The rejected shipments are subject to additional delivery fees prior to sending the exchanged items. In case of manufacturing defect or fault from the part of Ghayar, the company shall receive the items unquestionably and bear the return costs; subject to the in-house return policy. We deliver to about 180 countries as our supply record shows. Fragile items (windshield and rear door glass) are subject to wood box fees ($40-190), depending on the box size and destination
                </p>
                <h2 className="subtitle">Governing Law/Jurisdiction</h2>
                <p>
                    The content of the policy herein is subject to the United Arab Emirates (UAE) laws, Emirate of Dubai, regardless of choice or conflicting principles of law. The customer shall thus agree to turn to the UAE Jurisdiction in case of any conflict, claim or dispute with regard to agreements and whatever emanating therefrom.
                </p>
                <h2 className="subtitle">Contact Us</h2>
                <p>In case of queries not addressed hereinabove, please contact us through the following means.</p>

                <ul>
                <li>Company Tel: +97142551383</li>
                <li>Toll-free: 800442522</li>
                <li>Mob: +97155 760 0255</li>
                <li>Email: contact@ghayar.ae</li>
                </ul>
            </div>
          
            }
          </div>
         
        </main>
          )
}

export default Delivery;