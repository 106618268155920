import React, { useContext, useEffect, useState } from "react";

import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../Context/ConfigContext";

import { useParams } from "react-router-dom";
import { Spinner } from "../../Components/Shimmers";
import slugify from "slugify";
import Carousel from "react-multi-carousel";
import PartsHorizontalCard from "../../Components/Cards/PartsHorizontalCard";
import CarSearchBox from "../../Layout/CarSeachBox";
import OEParts from "../OESpareParts/OEParts";
import { Helmet } from "react-helmet";
import NotifyMeForm from "../../Components/NotifyMeForm";

const NoResults = () => {
  const {currency} = useContext(ConfigContext);
  const location = useLocation();
  const { t } = useTranslation();
  const locale = localStorage.getItem("lang") ?? "en";
  const { gdcURL } = useContext(ConfigContext);
  const dir = localStorage.getItem("dir");
  const { keyword } = useParams();

  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState([]);
  const [brands, setBrands] = useState([]);

  
  const [car_info, setCarInfo] = useState(
    localStorage.getItem("car_info")
      ? JSON.parse(localStorage.getItem("car_info"))
      : []
  );

  
  
  
  const [isSticky, setSticky] = useState(false);
  
  const handleScroll = () => {
    const offset = window.scrollY;
    setSticky(offset > 300); // Change 100 to the scroll value you prefer
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    console.log("isSticky", isSticky);
  }, [isSticky])
  return (
    <main className="main" dir={dir}>
      
      <div className="row">
        <div className="col-md-3">
          <CarSearchBox redirectStatus={true} />
          
          
        </div>
        <div className="col-md-9">
          <div className="container">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to={"/"}>
                    <i className="icon-home" />
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={() => {}}>{t("home")}</NavLink>
                </li>
                
              </ol>
            </nav>

            
            
            <div className="container mb-1 mt-1 pb-2 pt-2">
                <div className="alert alert-info" style={{ textAlign: "center" }}>
                  <div className="col-sm-12">
                    {localStorage.getItem("lang") === null || localStorage.getItem("lang") === "en" ?
                      <>
                      <div className="no-results">
                          <h2>0 results found for {keyword}                         </h2>
                          <p>Can't find a spare part? Check our catalogue, or send us a request.</p>
                          <NavLink to={`/Spare-Parts/Catelog`} className="btn btn-primary">{t('open_catelog')}</NavLink>
                      </div>
                      </>
                      :

                      <div className="no-results" >
                        <h2>لا توجد نتائج</h2>
                        <p>لم نتمكن من العثور على أي قطع تطابق بحثك. يرجى تعديل كلمات البحث أو استكشاف الفئات الأخرى.</p>
                        <NavLink to={`/Spare-Parts/Catelog`} className="btn btn-primary">{t('open_catelog')}</NavLink>
                      </div>
                      }
                    </div>
                </div>
                <NotifyMeForm style={{border: '1px solid #CCC', padding: '20px'}}/>
              </div>
            
          </div>
        </div>
      </div>
    </main>
  );
};

export default NoResults;
