import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const SafeShopping = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('safe_shopping')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('safe_shopping')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'xar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
              <h2 className="subtitle">بماذا تتميز شركة غيار ؟</h2>
              <p>
              تتميز شركة غيار بأنها توفر جميع أنواع قطع الغيار؛ لجميع أنواع السيارات، وتملك شركة غيار موقعًا إلكترونيًا: www.ghayar.ae مع تطبيقات ذكيةghayar : (غيار)؛ تم تصميمها وفق أفضل الممارسات العالمية مما يسهل على المتعامل الحصول على القطع بأسهل الطرق؛ مع خدمة توصيل سريعة وآمنة، وتتم إدارة مبيعات الشركة من خلال مركز العمليات في إمارة دبي؛ الذي يسعى إلى تقديم العناية التامة بالمتعاملين، وتلتزم شركة غيار بسياسات واضحة لضمان القطع مع مرونة في الإرجاع.
              </p>
              <h2 className="subtitle">لماذا يجب علي تسجيل الدخول قبل شراء قطع الغيار؟</h2>
              <p>
              تقوم شركة غيار بمتابعة طلبات عملائها منذ القيام بعمليات الشراء وصولًا إلى التسليم وتقديم خدمات متميزة لما بعد البيع؛ وعليه، لا يمكننا القيام بذلك دون حفظ بيانات المتعامل الرئيسية التي تتم من خلال تسجيل الدخول، والتي تساعدنا كثيرًا على فهم رغبات المتعاملين واحتياجاتهم حتى نقوم بتوفيرها في أسرع وقت ممكن.
              </p>
            </div>:
            <div className="container">              
                <h4 class="post__title">Safe Shopping</h4>
                <p>Here at ‘Ghayar’ portal company take your online security very seriously. We use high-level 128-bit encryption technology, the most advanced security software currently available for online transactions on our website. Rest assured that we take the privacy and security of your personal and payment details very seriously.</p>
                <p>This security technology encrypts your personal information including your credit card number, name and address, ensuring that nothing can be read as it travels over the internet.</p>
                <p>You can always check to see whether a page is secure as 'https' will replace 'http' in your browser address window. For example: 'https://www.ghayar.ae'. A small padlock will then appear in the bottom bar on the right-hand side of your browser window.</p>
                <i>Verified by Visa and Master Card -Secure Code-</i>
                <p>To give you even more confidence in shopping online with eurocarparts.com, we have introduced Verified by Visa and Mastercard® Secure Code™. These services enhance your existing card account against unauthorized use when you shop with us.</p>
                To use this service, you must first register with the bank or other organization that issued your card. To find out more about these services:
                <br /><u>Click here to visit the Verified by Visa website</u>
                <br /><u>Click here to visit the Mastercard® Secure Code™ website</u>
                <h4 class="post__title">How do you secure my payments?</h4>
                <p>We employ state-of-the-art data encryption to ensure safe and secure transactions on our site.</p>
                <p>Our payment area is secured using industry standard Secure Sockets Layer technology (SSL). You'll know when you enter this area because the small yellow padlock appears in the bottom right hand corner of your browser. The other indication is in the address bar. You'll notice that http:// is replaced by https:// (the 's' standing for 'Secure').</p>
                <p>Your Internet browser provides visual indications that you are inside a secure site and that all data transferred is securely encrypted.</p>
            </div>
          
            }
          </div>
         
        </main>
          )
}

export default SafeShopping;