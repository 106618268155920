import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { components } from 'react-select';
import { ConfigContext } from '../Context/ConfigContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AddToGarage } from '../Helper/MyCars';

const CarSearchBox = ({redirectStatus, home, setShowModalGarage}) => {
  const { t } = useTranslation();
  // const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const locale = localStorage.getItem('lang');
  
  const [models, setModels] = useState([]);
  const [makesOptions, setMakesOptions] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [fuelList, setFuelList] = useState([]);
  const [enginesList, setEnginesList] = useState([]);
  const [selected_engine, setSelectedEngine] = useState(null);
  const [selected_year, setSelectedYear] = useState(0);
  const [selected_fuel_type, setSelectedFuelType] = useState(null);
  const [series, setSeries] = useState([]);
  const [mfa_id, setMFAID] = useState(0);
  const [from_year, setFROMYEAR] = useState(1970);
  const [ms_id, setMSID] = useState(0);
  const [pc_id, setPCID] = useState(localStorage.getItem('pc_id')??0);
  const [vin, setVIN] = useState('');
  const [car_info, setCarInfo] = useState(localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info')): []);
  const [vinLoading, setVINLoading] = useState(false);
  const {gdcURL, setContextMSID, PCID, setContextPCID, makes, setMakes, setCategories, categories} = useContext(ConfigContext);
  const dir = localStorage.getItem('dir');
  const [loading, setLoading] = useState(true);
  const LoadCategories = () => {

  }


  useEffect(() => {
      
    window.scrollTo(0, 0);
    if(localStorage.getItem('pc_id') && localStorage.getItem('pc_id')!=='' && localStorage.getItem('pc_id')!=='0')
    {
      // alert(`${gdcURL}/Categories/GetCategoriesByCar?pc_id=${PCID}&lang=${locale}`)
      setLoading(true);
      axios.get(`${gdcURL}/Categories/GetCategoriesByCar?pc_id=${PCID}&lang=${locale}`)
      .then(data => {
        
        setCategories(JSON.parse(JSON.stringify(data.data.data))); 
        setLoading(false);
      })
    }else{
      setLoading(true);
      axios.get(`${gdcURL}/Categories/GetCategories?lang=${locale}`)
      .then(data => {
        
        setCategories(JSON.parse(JSON.stringify(data.data.data))); 
        setLoading(false);
      })
    }
       
    }, [PCID, pc_id]);
  useEffect(() => {
    
  },[pc_id, car_info, PCID])
  useEffect(() => {
    createFuelList();
  }, [selected_engine])
  useEffect(() => {
    if(makes.length > 0)
    {
      const popularItems = makes.filter(item => item.MFA_POPULAR === 1);
      const nonPopularItems = makes.filter(item => item.MFA_POPULAR === 0);
      const groupedOptions = [
        {
          label: 'Popular Carmakers',
          options: popularItems,
        },
        {
          label: 'Carmaker are arranged in alphabatical order',
          options: nonPopularItems,
        },
      ];
      
      setMakesOptions(groupedOptions);
      setMSID(0);
      setModels([]);
    }
  }, [makes])
  useEffect(() => {
    fetch(`${gdcURL}/Cars/GetMakes`)
      .then((res) => res.json())
      .then((data) => {
        setMakes(data.data); 
        setModels([]);
        setYearList([]);
      })
  }, []);

  useEffect(() => {
    if(mfa_id !== 0)
    {
      setMSID(0);
      
      setPCID(0);
     
      setModels([]);
      setSeries([]);
      
      setFuelList([]);
      setEnginesList([]);
      setSelectedEngine(null);
      setSelectedFuelType(null);
      if(selected_year && mfa_id){
      fetch(`${gdcURL}/Cars/GetModels?mfa_id=${mfa_id}&year=${selected_year}`)
        .then((res) => res.json())
        .then((data) => {
          console.log("Models: ", data.data)
          setModels(data.data); 
          
        })
      }
    }
    
  }, [selected_year]);
  useEffect(() => {
    
    CreateYearList();
  }, [mfa_id])

  useEffect(() => {
    if(ms_id !== 0)
    {
      setSeries([]);
      setPCID(0);
      setFuelList([]);
      setSelectedFuelType(null);
      setEnginesList([]);
      setSelectedEngine(null);
      console.log('GetSeries called')
      // alert(selected_year)
      fetch(`${gdcURL}/Cars/GetSeries?ms_id=${ms_id}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(`${gdcURL}/Cars/GetSeries?ms_id=${ms_id}`);
          // alert(ms_id)
          var seriess = filterByYear(data.data, selected_year);
          // console.log("seriess", seriess);
          setSeries(seriess); // Assuming your data has id and name properties
          createEngineList(seriess);
        })
    }
    
  }, [ms_id]);
  
  const CreateYearList = (dateFrom, dateTo) =>{
    setSeries([]);
    setYearList([]);
    setSelectedYear(0);
    setPCID(0);
    setFuelList([]);
    setSelectedFuelType(null);
    setEnginesList([]);
    setSelectedEngine(null);

    const yearsList = [];
    for (let year = 2024; year > from_year; year--) {
      yearsList.push({ label: year, value: year });
    }
   
    setYearList(yearsList);
  };
  const xCreateYearList = (dateFrom, dateTo) =>{
    setSeries([]);
    setYearList([]);
    setSelectedYear(0);
    setPCID(0);
    setFuelList([]);
    setSelectedFuelType(null);
    setEnginesList([]);
    setSelectedEngine(null);
    console.log('CreateYearList called')
    // Convert date strings to Date objects
    const dateFromDt = new Date(dateFrom);
    const dateToDt = dateTo!==null?new Date(dateTo): new Date();

    // Extract years
    const startYear = dateFromDt.getUTCFullYear();
    const endYear = dateToDt.getUTCFullYear();

    // Generate the list of years
    const yearsList = [];
    for (let year = startYear; year <= endYear; year++) {
      yearsList.push({ label: year, value: year });
    }
   
    setYearList(yearsList);
  }
  const createEngineList = (data) => {
    var tempEngines = [];
    setFuelList([]);
    setSelectedFuelType(null);
    setEnginesList([]);
    setSelectedEngine(null);
    for(var i = 0; i < data.length; i++){
      if(!tempEngines.find((item) => item.label === data[i].label)){
        tempEngines.push({label: data[i].label, value: data[i].label, PC_FUEL_TYPE: data[i].PC_FUEL_TYPE});
      }
    }
    setEnginesList(tempEngines);
  }

  const createFuelList = () => {
    var tempFuels = [];
    setPCID(0);
   
    
    setFuelList([]);
    for(var i = 0; i < series.length; i++){
      console.log("tag1",series[i]);
      console.log("selectedengines", selected_engine,   series[i].label)
      if(selected_engine ===  series[i].label)
      {
        if(!tempFuels.find((item) => item.label === series[i].PC_FUEL_TYPE)){
          tempFuels.push({label: series[i].PC_FUEL_TYPE, value: series[i].PC_ID});
        }
      }
    }
    
    
    setFuelList(tempFuels);
  }


  function filterByYear(vehicles, selectedYear) {
    var filteredVehicles = [];
    for(var i =0; i < vehicles.length; i++){
      var vehicle = vehicles[i];
      const ciFromYear = new Date(vehicle.PCS_CI_FROM).getUTCFullYear();
      const ciToYear = vehicle.PCS_CI_TO ? new Date(vehicle.PCS_CI_TO).getUTCFullYear() : new Date().getUTCFullYear();
      
     
      if(ciFromYear <= selectedYear &&  ciToYear >= selectedYear){
        filteredVehicles.push(vehicle);
      }
    }
    
  return filteredVehicles;
}
  const SaveCar = async() => {
    if(vin !== ''){
      GetVIN();
      return false;
    }
    if(localStorage.getItem('pc_id') && Number(localStorage.getItem('pc_id')) > 0){

    }else if(pc_id === 0){
      alert('Select Car');
      return false;
    }
    if(pc_id && ms_id){
      localStorage.setItem('pc_id', pc_id.toString());
      localStorage.setItem('ms_id', ms_id);
      setContextPCID(pc_id);
      setContextMSID(ms_id);
      
      var car = await GetCarInfo();
      AddToGarage(car);
      if(setShowModalGarage) {
        setShowModalGarage(false);
      }
    }
    
     if(redirectStatus && !home){
       window.location.reload();
     }else{
      navigate(`/Spare-Parts/Catelog`);
      
     }
    
  }
  const GetCarInfo = async () => {
    var response = await axios.get(`${gdcURL}/Cars/GetCarInfo?pc_id=${localStorage.getItem('pc_id')}&lang=${locale}`);
    var data= response.data;
    
    localStorage.setItem('car_info', JSON.stringify(data.data));
    setCarImg(`${gdcURL}/car-ms-images/${localStorage.getItem('ms_id')}`);
    setCarInfo(data.data)
    setContextPCID(data.data[0].PC_ID)
    setContextMSID(data.data[0].PC_MS_ID)
    return data.data[0];
    
  }
  const GetVIN = async() => {
    if(vin.length <17){
      alert('Invalid VIN');
      return false;
    }
    setVINLoading(true);
    fetch(`${gdcURL}/Cars/GetVINDecode?vin=${vin}`)
    .then((res) => res.text())
    .then(async(data) => {
      var vindata =  JSON.parse(data);
      vindata =  JSON.parse(vindata);
      setVINLoading(false);
      if(vindata.result.statusMsg === "Success"){
        if(vindata.result[0].carId)
        {
          await localStorage.setItem('pc_id',vindata.result[0].carId);
          var car = await GetCarInfo();
          AddToGarage(car);
          if(setShowModalGarage) {
            setShowModalGarage(false);
          }
          navigate(`/Spare-Parts/Catelog`);
        }else{
          alert("Not Found, Please select from above menu")
        }
      }else{
        alert("Not Found, Please select from above menu")
      }
      
    })
  }
  const removeCar = () => {
   
    localStorage.setItem('pc_id', '');
    localStorage.setItem('ms_id', '');
    localStorage.setItem('car_info', '');
    setPCID(0);
    
    setCarInfo([]);
    if(redirectStatus){
      window.location.reload()
    }else{
      navigate(`/Spare-Parts/Catelog`)
    }
    
  }
  const removeCarWithReload = () => {
   
    localStorage.setItem('pc_id', '');
    localStorage.setItem('ms_id', '');
    localStorage.setItem('car_info', '');
    setPCID(0);
    
    setCarInfo([]); 
    
    
  }
  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: setShowModalGarage?990999:999,textAlign: dir==='rtl'?'right': 'left', }),
    control: base => ({ ...base, zIndex: setShowModalGarage?990999:999,textAlign: dir==='rtl'?'right': 'left',paddingLeft: '10px'  }),
    placeholder: base => ({ ...base, zIndex: setShowModalGarage?990999:999,textAlign: dir==='rtl'?'right': 'left' })
  };
  const [carImg, setCarImg] = useState(`${gdcURL}/car-ms-images/${localStorage.getItem('ms_id')}`);
  
  if(!localStorage.getItem('pc_id') && PCID !== 0 && car_info.length > 0){
    
    return <div className="container my-5" >
    <div className="selected-car p-2 row">
      <div className="col-lg-12 selected-img" >
        <img src={carImg}  style={{  width: "auto" }} className="img-fluid" alt={car_info[0].TYPEL} 
        onError={e => {setCarImg(`${window.location.protocol + '//' + window.location.host}/assets/images/placeholder_car.png`)}}
        // onError={e => {  e.currentTarget.onerror = null; e.currentTarget.src = 'assets/images/placeholder_car.png'}}
        // onError={({ currentTarget }) => {
        //   currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
        //   currentTarget.src = "assets/images/placeholder_car.png";
        // }}
        />
      </div>
      <div className="col-lg-12 selected-car-text" style={{textAlign: 'center'}}>
        <div>
        <h4 className="text-dark h4 mb-0 mt-5">
          {car_info[0].TYPEL} {car_info[0].PC_ENG_CODES} 
        </h4>
        {t("year")}: {car_info[0].PCS_CI_FROM.split('-')[0]} - {car_info[0].PCS_CI_TO?car_info[0].PCS_CI_TO.split('-')[0]: 'Till Now'} | {t("fuel_type")}: {car_info[0].PC_FUEL_TYPE}
        </div>
        <div>
        
          
        </div>
      </div>
      <div className='col-lg-12 mt-5' style={{textAlign: 'center'}}>
        <button className="btn btn-outline-primary btn-ellipse" onClick={() => { removeCar(); }} > {t("change_car")} </button>
        <button className="btn btn-outline-primary btn-ellipse mr-2 ml-2" onClick={() => { navigate(`/Spare-Parts/Catelog`); }} > {t("browse_catelog")} </button>
      </div>
    </div>
  </div>
  }
  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <span style={{backgroundColor: props.selectProps.isDisabled?'#CCC':'#cc000b', borderRadius: '50px', width: '20px', textAlign: 'center', color: '#FFF', fontSize: '10px', paddingTop: '3px', paddingBottom: '3px'}}>{props.selectProps.srNo}</span>
      {children}
    </components.Control>
  );
  return (
    <div dir={dir}  style={{backgroundColor: '#cc000b', borderRadius: 10 }}>
      {car_info.length !== 0 && <div onClick={() => removeCar()} style={{position: 'absolute',right: 20, top: 20, zIndex:1000}}><button className='btn btn-outline-light btn-ellipse btn-sm' style={{textTransform: 'capitalize'}}><i className="sicon-note" style={{fontSize: '10px'}}></i> {t('change_car')}</button></div>}
        <div className="row justify-content-center pl-4 pr-4 pt-3">
            <div className={`col-12 d-flex justify-content-center align-items-center col-md-12 pb-4`}>
                <svg viewBox="0 0 32 32" className="sc-1k8rycc-0 huZjNT" style={{ marginLeft: "10px", marginTop: "5px", padding: "5px", border: "2px solid white", borderRadius: "50%", width: "50px", height: "50px", }} >
                <path d="M27.867 12.667h-1.733c-0.667-0.933-0.667-0.933-1.467-2.267-1.2-2-3.2-2.4-3.2-2.4l-5.333-0.133-5.467 0.133c0 0-2 0.533-3.333 2.533-0.933 1.333-0.667 0.8-1.467 2.133h-1.733c-0.533 0-0.933 0.4-0.933 0.933 0 0 0 0 0 0v0.133c0 0.533 0.4 0.933 0.933 0.933h1.067l-0.533 1.6c0 0-0.267 0.533-0.267 1.867v5.867c0 0.667 0.533 1.333 1.333 1.333h2.133c0.667 0 1.333-0.533 1.333-1.333v-0.8h13.867v0.8c0 0.667 0.533 1.333 1.333 1.333h2.133c0.667 0 1.333-0.533 1.333-1.333v-6c0-1.333-0.267-1.867-0.267-1.867l-0.667-1.467h1.067c0.533 0 0.933-0.4 0.933-0.933v-0.133c-0.133-0.533-0.533-0.933-1.067-0.933zM9.067 19.467l-2.8-0.133c-0.4-0.133-0.667-0.4-0.8-0.8s-0.133-1.067 0-1.467c0.267-0.8 1.2-0.4 1.733-0.133 0.8 0.267 1.6 0.667 2.4 0.933 0.533 0.133 0.8 0.533 0.8 1.067-0.133 0.667-0.933 0.533-1.333 0.533zM16 14.533c-4.133 0-8.667-0.8-8.667-0.8l1.333-2.667c0.533-1.467 2.667-1.6 2.667-1.6l4.667-0.133h4.667c0 0 2 0.133 2.533 1.6l1.333 2.667c0.133 0.133-4.4 0.933-8.533 0.933v0zM26.533 18.533c-0.133 0.4-0.4 0.667-0.8 0.8l-2.8 0.133c-0.533 0-1.2 0-1.333-0.533s0.267-0.933 0.8-1.067c0.8-0.267 1.6-0.667 2.4-0.933 0.533-0.133 1.467-0.533 1.733 0.133 0.133 0.4 0.133 1.067 0 1.467z" fill="white" ></path>
                </svg>
            </div>
            
            <div className="row" style={{width: '100%'}}>
              <div className='pb-2 col-12 d-flex col-sm-10  '>
                <input style={{borderRadius: '5px' ,height: '38px'}} className="form-control" placeholder={t("enter_vin")} value={vin} onKeyUp={event => { if (event.key === 'Enter') { SaveCar() } }} onChange={(e) => setVIN(e.target.value.replaceAll(' ', ''))} />
              </div>
              <div className='pb-2 col-12 d-flex col-sm-2  justify-content-center align-items-center '>
              <button className={`btn btn-outline-light pl-3 pr-3`} style={{ marginTop: '-10px',fontSize: "15px", borderRadius: "50px", fontWeight: "bold",padding: '0px',height: '40px',background: '#FFF',color: '#cc000b' }} type='button' disabled={vinLoading}  onClick={SaveCar} >{vinLoading ? <i className="fas fa-spinner fa-spin"></i> : t('go')} </button>
              </div>
            </div>
            <h5 style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid rgb(204 199 199)', lineHeight: '0.1em', margin: '10px 0 20px'}}><span style={{background:'1px solid rgb(204 199 199)', padding:'10 0px', color: '#FFF', backgroundColor: '#cc000b' }}>OR</span></h5>
            <div className="row" style={{width: '100%'}}>
                <div className='col-12 col-sm-7 pb-4 d-flex '>
                  <Select srNo={'1'}  components={{ Control: CustomControl, DropdownIndicator: null }} menuPortalTarget={document.body} styles={customStyles} className='w-100' isDisabled={vin ? true : false} placeholder={car_info && car_info[0]?car_info[0].MFA_BRAND:t("car_make")} options={makesOptions} onChange={(option) => { removeCarWithReload(); setMFAID(option.value); setFROMYEAR(option.FROM_YEAR)}} />
                </div>
                <div className='col-12 col-sm-5 pb-4  d-flex '>
                <Select  srNo={'2'} components={{ Control: CustomControl, DropdownIndicator: null }} menuPortalTarget={document.body} styles={customStyles} className='w-100' isDisabled={yearList.length <= 0 || vin ? true : false} value={selected_year <= 0 || vin ? null : yearList.find((item) => item.value === selected_year)} placeholder={car_info && car_info[0]?car_info[0].PCS_CI_FROM.split('-')[0]:t("year")} options={yearList} onChange={(option) => { setSelectedYear(option.value); setSelectedEngine(null) }} />
                </div>
            </div>
            
            <div className="row" style={{width: '100%'}}>
                <div className='col-12 col-sm-12 pb-4 d-flex '>
                <Select srNo={'3'} components={{ Control: CustomControl, DropdownIndicator: null }} menuPortalTarget={document.body} styles={customStyles} className='w-100' isDisabled={models.length <= 0 || vin  ? true : false} value={ms_id <= 0 || vin ? null : models.find((item) => item.value === ms_id)} placeholder={car_info && car_info[0]?car_info[0].TYPEL:t("model")} options={models} onChange={(option) => { setMSID(option.value); }} />
                </div>
                
                
            </div>
            <div className="row" style={{width: '100%'}}>
              <div className='col-12 col-sm-12 pb-4  d-flex '>
                  <Select srNo={'4'} components={{ Control: CustomControl, DropdownIndicator: null }} menuPortalTarget={document.body} styles={customStyles} className='w-100' isDisabled={enginesList.length <= 0 || vin ? true : false} value={selected_engine === null || vin ? null : enginesList.find((item) => item.value === selected_engine)} placeholder={car_info && car_info[0]?car_info[0].PC_ENG_CODES:t("engine")} options={enginesList} onChange={(option) => { setSelectedEngine(option.value);  }} />
                </div>
                
            </div>
            <div className="row" style={{width: '100%'}}>
              <div className='col-12 col-sm-6 pb-4  d-flex '>
                  <Select srNo={'5'} components={{ Control: CustomControl, DropdownIndicator: null }} menuPortalTarget={document.body} styles={customStyles} className='w-100' isDisabled={fuelList.length <= 0 || vin ? true : false} value={pc_id <= 0 || vin ? null : fuelList.find((item) => item.value === selected_fuel_type)} placeholder={car_info && car_info[0]?car_info[0].PC_FUEL_TYPE:t("fuel")} options={fuelList} onChange={(option) => { setPCID(option.value);}} />
              </div>
              <div className='col-12 col-sm-6 pb-4 d-flex justify-content-end'>
                  <button className={`btn btn-outline-light pull-right`} style={{ width: "130px", fontSize: "15px", borderRadius: "50px", fontWeight: "bold",height: '40px',padding: '0px',background: '#FFF',color: '#cc000b' }} type='button' disabled={vinLoading} onClick={SaveCar} >{vinLoading ? <i className="fas fa-spinner fa-spin" /> : t('search')} </button> 
              </div>
            </div>
            
            
           
            
        </div>

        
    </div>
  );
}

export default CarSearchBox;
