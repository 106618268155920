import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../Context/ConfigContext";
import slugify from "slugify";
import { useTranslation } from "react-i18next";
import Carousel from 'react-multi-carousel';
import { AddToCart } from "../../../Helper/MyCart";
import { ToastContainer, toast } from 'react-toastify';
import { AddToCartNotifier } from "../../../Components/CartComponents";
import { NavLink } from "react-router-dom";
const PriceComparison = ({STR_ID, PART_TITLE}) => {
    
    const {gdcURL, apiURL, setCartItemsQty} = useContext(ConfigContext);
   
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    const [showCriteria, setShowCriteria] = useState({});
    const handleAddToCart = async(part) => {
      
        const partImg = part.MEDIA_SOURCE ? `${gdcURL}/images/${part.MEDIA_SOURCE}` : `${apiURL}/public/placeholder_category.jpeg`;
        const url = `/Spare-Parts/Details/${slugify(`${part.PRODUCT_GROUP}`.toLowerCase())}-${slugify(`${part.ART_ARTICLE_NR}`.toLowerCase())}-${STR_ID}-${slugify(`${part.SUP_ID}`.toLowerCase())}-${slugify(`${part.ART_ID}`.toLowerCase())}`;
        await AddToCart(part.ART_ID,part.ART_ARTICLE_NR, part.PRODUCT_GROUP, part.mrp??0, part.price??0,  1, partImg, url);
        setCartItemsQty();
        
        
        toast.success(<AddToCartNotifier item_name={part.PRODUCT_GROUP}  item_img={partImg}/>, {
          onClose: () => {
           
          }
        })
        
        
      }
    const toggleCriteria = (index) => {
        setShowCriteria((prevShowCriteria) => ({
          ...prevShowCriteria,
          [index]: !prevShowCriteria[index],
        }));
      };
    const [responsive, setResponsive] =  useState({
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      })
    useEffect(() => {
        if(localStorage.getItem('pc_id'))
        {
          setLoading(true);   
        fetch(`${gdcURL}/Parts/GetPartsByCar?pc_id=${localStorage.getItem('pc_id')}&str_id=${STR_ID}&lang=${locale}`)
        .then((res) => res.json())
        .then((data) => {
            setParts(data.data);
            setLoading(false)
        })
        .catch((error) => {
            console.error('Fetch error:', error);
            setLoading(false);
        });
        }
    }, [STR_ID])
    return (
        <div className="container">
            <div className="pb-5 mt-5" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'left'}}>
                <h4>{t('spart_part_with_similar_chars', {part: PART_TITLE})}</h4>
                <div className="row">
                    {
                        parts.slice(0, 6).map((part, index) => {
                            const url = `/Spare-Parts/Details/${slugify(`${part.PRODUCT_GROUP}`.toLowerCase())}-${slugify(`${part.ART_ARTICLE_NR}`.toLowerCase())}-${STR_ID}-${slugify(`${part.SUP_ID}`.toLowerCase())}-${slugify(`${part.ART_ID}`.toLowerCase())}`;
                            
                            return (
                                <div className="col-md-6 p-4" key={index} style={{textAlign: 'center', border: '1px solid #e6e4e4', marginRight: '-1px', marginBottom: '-1px'}}>
                                    <NavLink to={url}>
                                        <span style={{float: 'left'}}>{part.ART_SUP_BRAND}</span>  <span>{part.ART_ARTICLE_NR}</span> <span style={{float: 'right'}}>00.0</span>
                                    </NavLink>
                                </div>
                            )
                            })
                    }

                    </div>
                
            </div>
        </div>
    )
}
export default PriceComparison;