import { useTranslation } from "react-i18next";
import {
  ClearGarage,
  GetGarages,
  RemoveCarGarage,
  SelectCarGarage,
} from "../Helper/MyCars";
import { useContext, useEffect, useRef, useState } from "react";
import { ConfigContext } from "../Context/ConfigContext";
import { useNavigate } from "react-router-dom";
import CarSearchBox from "./CarSeachBox";

const MyGarage = () => {
  const [myCars, setMyCars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { MyGarageCars, setMyGarageCars, setContextPCID, setContextMSID } =
    useContext(ConfigContext);
  const dir = localStorage.getItem("dir");
  const navigate = useNavigate();
  const handleSelectCarGarage = async (index) => {
    var cars = await SelectCarGarage(index);
    console.log("gogo", cars);
    setContextPCID(cars[index].PC_ID);
    setContextMSID(cars[index].PC_MS_ID);
    localStorage.setItem("ms_id", cars[index].PC_MS_ID);
    localStorage.setItem("pc_id", cars[index].PC_ID);
    setMyGarageCars(cars);
    var carss = [];
    carss.push(cars[index]);
    localStorage.setItem("car_info", JSON.stringify(carss));

    window.location = "/Spare-Parts/Catelog";
  };
  const handleSelectCarRemove = async (index) => {
    RemoveCarGarage(index);

    window.location.reload();
  };
  const handleClearHisotry = async () => {
    await ClearGarage();
    setMyGarageCars([]);
  };
  useEffect(() => {

  }, [MyGarageCars])
  const divRef = useRef(null);  // Reference to the div 

  useEffect(() => {
    // Function to detect if the click is outside of the div
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowModal(false);  // Close the div or trigger any action on outside click
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <div>
          <div
            className="p-4"

            dir={dir}
            style={{ textAlign: dir === "rtl" ? "right" : "left" }}>
            <h3>{t("my_cars")}</h3>
            <button data-toggle="modal" data-target="#exampleModal" onClick={() => setShowModal(true)} type="button" className="btn btn-primary" style={{ width: '100%' }}>{t('add_new_car')}</button>
            <ul>
              <li style={{ marginTop: "12px", color: "#132530", fontSize: "13px" }}>
                <svg
                  className="header-nav__icon base-svg mr-2"
                  role="img"
                  style={{ width: "20px", height: "20px", fill: "#184460" }}>
                  {" "}
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-garage-car-icon-bw"></use>{" "}
                </svg>
                {t("save_to_garage")}
              </li>

              <li style={{ marginTop: "12px", color: "#132530", fontSize: "13px" }}>
                <svg
                  className="header-nav__icon base-svg mr-2"
                  role="img"
                  style={{ width: "20px", height: "20px", fill: "#184460" }}>
                  {" "}
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-target-icon-bw"></use>{" "}
                </svg>
                {t("quick_search_for_saerch")}
              </li>

              <li style={{ marginTop: "12px", color: "#132530", fontSize: "13px" }}>
                <svg
                  className="header-nav__icon base-svg mr-2"
                  role="img"
                  style={{ width: "20px", height: "20px", fill: "#184460" }}>
                  {" "}
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-like-icon-bw"></use>{" "}
                </svg>
                {t("guaranteed_compatibility")}
              </li>
            </ul>
            <div className="row">
              <div className="col-sm-8">
                <span style={{ fontWeight: "bold", fontSize: "large" }}>
                  {t("history")}
                </span>
              </div>
              <div
                className="col-sm-4 pt-2"
                style={{ color: "#0068d7", fontWeight: "600", textAlign: "right" }}>
                <span
                  onClick={() => handleClearHisotry()}
                  style={{ color: "#0068d7", fontWeight: "600", textAlign: "right" }}>
                  {t("clear_history")}
                </span>
              </div>
            </div>
            <table>
              <tbody>
                <tr>
                  <td width={"74%"}></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            {MyGarageCars?.map((car, index) => {
              return (
                <div
                  className="row pt-4 pb-2"
                  key={`cars-index-${index}`}
                  style={{
                    borderBottom: "1px solid rgb(230, 230, 230)",
                    marginTop: 0,
                    marginBottom: 0,
                  }}>
                  <div className="col-sm-1">
                    <input
                      type="radio"
                      checked={car.selected === 1}
                      className=""
                      name={`car-select-${index}`}
                      readOnly
                      onClick={() => {
                        handleSelectCarGarage(index);
                      }}
                    />
                  </div>
                  <div className="col-sm-8" onClick={() => {
                    handleSelectCarGarage(index);
                  }}>
                    <label className=" ">{car.TYPEL}</label>
                  </div>
                  <div className="col-sm-1">
                    <button
                      onClick={() => handleSelectCarGarage(index)}
                      className="btn btn-xs btn-primary"
                      style={{ marginTop: "-5px" }}>
                      <i
                        className="sicon-arrow-right align-right"
                        style={{ fontSize: "12px" }}
                      />
                    </button>
                  </div>
                  <div className="col-sm-1">
                    <div
                      onClick={() => handleSelectCarRemove(index)}
                      style={{ marginTop: "-2px", marginLeft: "10px" }}>
                      <i
                        className="fas fa-trash-alt align-right"
                        style={{ fontSize: "12px", color: "rgb(0, 104, 215)" }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
        id="subcatsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="subcatsModalLabel"
        aria-hidden="true"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>

        <div ref={divRef} className="modal-dialog modal-sm" role="document" style={{ maxWidth: '400px' }}>
          <div className="modal-content">
            <div className="modal-body">
              <div className="container">
                <CarSearchBox setShowModalGarage={setShowModal} />
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default MyGarage;
