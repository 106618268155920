import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const Warranty = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('warranty_coverage')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('warranty_coverage')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'ar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
             
                <p>
                ضمان الشركة المصنعة هو الحل الحصري لأي عيب في المنتج؛ وعليه، يرجى الرجوع إلى إرشادات الشركة المصنعة للتأكد من أنه يمكنك الاستفادة من ضمان المنتج الخاص بك؛ وبهذا، لا تتحمل شركة غيار أي ضمانات صريحة أو ضمنية أخرى للمنتجات المشمولة بضمان الشركة المصنعة.
                </p>
                 
                <h2 className="subtitle">سياسة الإرجاع</h2>
                <p>
                أجزاء شركة غيار قابلة للإرجاع شريطة عدم تثبيتها أو تفكيكها أو تركيبها أو تعديلها أو إتلافها، وإعادتها خلال 3 أيام من استلام الطلب، ويجب أن تكون جميع العائدات المصرح بها غير مستخدمة وفي حالتها الأصلية؛ بما في ذلك جميع العناصر والمكونات التي تم تضمينها في الحزمة الأصلية، وأما الأجزاء التي يتم إرجاعها كأجزاء خاطئة أو معيبة فإنها تتم وفق إجراءات الإرجاع أدناه خلال 7 أيام لجميع المنتجات. ملاحظة: الأجزاء الخطيرة والكهربائية مثل وحدة التحكم في المحرك والوسادة الهوائية غير قابلة للإرجاع إذا تم توصيلها أو استعمالها لأي سبب.
                </p>
                <p>
                وأما المنتجات غير المشمولة بضمان الشركة المصنعة، فإن شركة غيار توفر لك ضمانًا محدودًا لمدة 3 أيام للمنتجات التالفة من تاريخ استلام المنتج، ولا يغطي الضمان سوى استبدال الجزء المعيب، وإذا لم يكن من الممكن الحصول على هذا الاستبدال فسيتم إصدار استرداد كامل للمشتري، ولن تتم معالجة استرداد أو استبدال أي جزء معيب إلا إذا استوفى الشروط والأحكام التالية:
                </p>
                <ul>
                    <li>يجب أن تحتوي جميع المنتجات الخاضعة لمطالبة الضمان على عيب في التصنيع، ولا يشمل الضمان المنتجات التالفة بسبب الظروف البيئية بما في ذلك الأمطار والثلوج والبرد والملح أو الكوارث الطبيعية.</li>
                    <li>يجب ألا تتضرر العناصر بسبب التثبيت غير الصحيح، ولن تتم تغطية المنتجات التالفة بواسطة الضمان نتيجة لسوء الاستخدام أو الإصلاحات أو الصيانة غير الصحيحة أو التثبيت غير السليم أو التغيير أو التعديل.</li>
                    <li>يخضع الضمان لقيود الضمان العامة الموضحة أدناه.</li>
                </ul>

                
                <h2 className="subtitle">قيود الضمان العامة (تنطبق على جميع ضمانات المنتج)</h2>
                <p>لكل من ضمانات منتجاتنا المعروضة، تسري الأحكام التالية:</p>
                <ul>
                    <li>يقتصر الضمان فقط على العناصر التي لا تكون قابلة للتحويل نتيجة الاستخدام أو التأثر بعوامل أخرى أيًا كانت هذه العوامل.</li>
                    <li>لا يغطي الضمان أي تكاليف للعمالة أو الأضرار العرضية أو غير المباشرة أو الخاصة أو التبعية؛ على سبيل المثال لا الحصر: الإصابات الجسدية أو الأضرار التي تلحق بالممتلكات أو ضياع الوقت أو فقد استخدام السيارة أو الإزعاج أو رسوم الشحن الجوي أو رسوم القطر أو الإقامة الناتجة عن عيب في أو فشل الجزء.</li>
                    <li>سيتم إصدار بطاقة شحن مسبقة الدفع لجميع العائدات بسبب المطالبة بالضمان.</li>
                    <li>يجب أن تكون جميع مطالبات الضمان مصحوبة بفاتورة المبيعات الأصلية، وبطاقة ضمان الشركة المصنعة للبطاريات مثلًا، ويجب أن يكون لكل العناصر التي تم إرجاعها رقم RMA (تفويض إرجاع البضائع). يرجى الرجوع إلى سياسة الإرجاع الخاصة بنا لمزيد من التفاصيل، ولن يتم قبول العناصر التي تم إرجاعها بدون فاتورة مبيعات أصلية أو بدون أرقام RMA.</li>
                    <li>لا يغطي الضمان الحزم المفقودة أو المسروقة للشحن الخارجي؛ نظرًا لأن العميل هو الشاحن، فهو/هي الوحيد الذي يمكنه تقديم أي مطالبة مفقودة لدى شركة الشحن، ويجب تنسيق المطالبة ومعالجتها من خلال شركة الشحن من قبل العميل.</li>
                    <li>يمثل كل ضمان الحل الحصري لأي عيب في المنتج ومسؤوليتنا الكاملة عن أي جزء نضمنه، ولا نقدم أي ضمانات أخرى؛ صريحة أو ضمنية.</li>
                </ul>

                <h2 className="subtitle">القانون الحاكم / الولاية القضائية</h2>
               <p>تخضع مضمون هذه السياسة لقوانين دولة الإمارات العربية المتحدة إمارة دبي، بغض النظر عن الاختيار أو تضارب مبادئ القانون، وبهذا فأنت توافق على الولاية القضائية لدولة الإمارات العربية المتحدة لحل أي نزاع أو مطالبة أو خلاف يتعلق بالاتفاقات أو ما ينشأ بشأنها.</p>
                <h2 className="subtitle">اتصل بنا</h2>
                <p>إذا كانت لديك أي أسئلة حول السياسة أعلاه التي لم تتم الإجابة عليها، فيرجى الاتصال بنا بأي من الوسائل التالية:</p>

                <ul>
                    <li>الاتصال بنا عبر هاتف الشركة: 97142551383 +</li>
                    <li>أو الهاتف المجاني :800442522</li>
                    <li>أو الموبايل: 97155 760 0255+</li>
                    <li>مراسلتنا عبر البريد الإلكتروني:contact@ghayar.ae</li>
                </ul>
            </div>:
            <div className="container"> 
                <p>
                The manufacturing company warranty is the exclusive solution for any defect in product. Thus, please refer to the manufacturing company guidelines to benefit from the warranty thereof. Ghayar bears neither express nor implied warranty regarding products already under the manufacturing company warranty.

Products not under the manufacturing company warranty shall be under limited 3-days warranty following the receipt date of the product delivered. The warranty shall cover only the replacement of the defective part; in case of irreplaceability, the customer is entitled to full refund under the following terms and conditions:
                </p>
                <ul>
                    <li>In the event of manufacturing defect in the warranty claimable products. Warranty shall not cover flaws caused by environmental circumstances, including rain, hail, sault or natural disasters.</li>
                    <li>Items not damaged because of incorrect fixing, misuse, maintenance, change or adjustment.</li>
                    <li>Warranty is subject to the respective general terms stated down below.</li>
                   
                </ul>
                 
                <h2 className="subtitle">Warranty General Terms (Apply to all product warranties):</h2>
                <ul>
                    <li>Warranty is exclusive to items not transformable by use or effects of any type.</li>
                    <li>Warranty shall not cover cost of labor, accidental or direct damages therefrom, including but not limited to physical injuries or property damage, waste of time, vehicle disuse, interruption, airfreight charges, recovery charges or accommodation because of failure or part thereof.</li>
                    <li>Ghayar shall issue prepaid delivery card for all warranty claimable returns.</li>
                    <li>All warranty claims should include original purchase invoice and the manufacturing company warranty cards especially for Batteries with a return merchandise authorization (RMA). Please refer to our return policy for further details. No items return except with original purchase invoice and RMA.</li>
                    <li>Warranty does not cover lost/stolen abroad delivery packages. As when the client is the orderer of delivery abroad, he/she is the sole person to file any lost claim with the shipping company to tackle the claim thereof.</li>
                    <li>Any warranty from our part constitutes the exclusive solution for the defect on the respective product and our full responsibility for the part guaranteed; with no any other guaranties express or implied.</li>
                </ul>

                <h2 className="subtitle">Governing Law/Jurisdiction</h2>
                <p>The content of the policy herein is subject to the United Arab Emirates (UAE) laws, Emirate of Dubai, regardless of choice or conflicting principles of law. The customer shall thus agree to turn to the UAE Jurisdiction in case of any conflict, claim or dispute with regard to agreements and whatever emanating therefrom.</p>
                <h2 className="subtitle">Contact Us</h2>
                <p>In case of queries not addressed hereinabove, please contact us through the following means.</p>

                <ul>
                <li>Company Tel: +97142551383</li>
                <li>Toll-free: 800442522</li>
                <li>Mob: +97155 760 0255</li>
                <li>Email: contact@ghayar.ae</li>
                </ul>
            </div>
          
            }
          </div>
         
        </main>
          )
}

export default Warranty;