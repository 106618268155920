import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const Brands = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('brands')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('brands')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'xar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
              <h2 className="subtitle">بماذا تتميز شركة غيار ؟</h2>
              <p>
              تتميز شركة غيار بأنها توفر جميع أنواع قطع الغيار؛ لجميع أنواع السيارات، وتملك شركة غيار موقعًا إلكترونيًا: www.ghayar.ae مع تطبيقات ذكيةghayar : (غيار)؛ تم تصميمها وفق أفضل الممارسات العالمية مما يسهل على المتعامل الحصول على القطع بأسهل الطرق؛ مع خدمة توصيل سريعة وآمنة، وتتم إدارة مبيعات الشركة من خلال مركز العمليات في إمارة دبي؛ الذي يسعى إلى تقديم العناية التامة بالمتعاملين، وتلتزم شركة غيار بسياسات واضحة لضمان القطع مع مرونة في الإرجاع.
              </p>
              <h2 className="subtitle">لماذا يجب علي تسجيل الدخول قبل شراء قطع الغيار؟</h2>
              <p>
              تقوم شركة غيار بمتابعة طلبات عملائها منذ القيام بعمليات الشراء وصولًا إلى التسليم وتقديم خدمات متميزة لما بعد البيع؛ وعليه، لا يمكننا القيام بذلك دون حفظ بيانات المتعامل الرئيسية التي تتم من خلال تسجيل الدخول، والتي تساعدنا كثيرًا على فهم رغبات المتعاملين واحتياجاتهم حتى نقوم بتوفيرها في أسرع وقت ممكن.
              </p>
            </div>:
            <div className="container">
               <table class="table table-striped table-bordered">
                <tbody><tr><td>Alfa Romeo</td><td>Amaron</td><td>Audi</td><td>3M</td></tr>
                <tr><td>BMW</td><td>BP</td><td>Bosch</td><td>Stanley</td></tr>
                <tr><td>Chevrolet</td><td>Chrysler</td><td>Citron</td><td>Hitachi</td></tr>
                <tr><td>Daewoo</td><td>Daihatsu</td><td>DeWalt</td><td>Uken</td></tr>
                <tr><td>Ford</td><td>Makita</td><td>Black&amp;Decker</td><td>Volvo</td></tr>
                <tr><td>Honda</td><td>Hyundai</td><td>Jaguar</td><td>Varta</td></tr>
                <tr><td>Jeep</td><td>Kia</td><td>Land Rover</td><td>Suzuki</td></tr>
                <tr><td>Lexus</td><td>Mazda</td><td>Mercedes</td><td>Total</td></tr>
                <tr><td>Mini Cooper</td><td>Mitsubishi</td><td>Nissan</td><td>Toyota</td></tr>
                <tr><td>Peugeot</td><td>Porsche</td><td>Renault</td><td>MG</td></tr>
                <tr><td>Rover</td><td>Saab</td><td>Seat</td><td>Fiat</td></tr>
                <tr><td>Skoda</td><td>Subaru</td><td>Volkswagen</td><td>Hino</td></tr>
                </tbody></table>
            </div>
          
            }
          </div>
         
        </main>
          )
}

export default Brands;