import { t } from "i18next";
import { useTranslation } from "react-i18next";

const { useContext, useEffect } = require("react")
const { NavLink } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const Cancelation = () => {
    const {apiURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    return (
          <main className="main about" dir={dir} >
          <div className={`page-header page-header-bg`} style={{background: '50%/cover #D4E1EA url("/assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1>{t('cancelation_return')} </h1>
              
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('cancelation_return')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
            {localStorage.getItem('lang') === 'ar'?
            <div className="container" style={{textAlign: 'right'}} dir="rtl">
              <h2 className="subtitle">سياسة الإلغاء</h2>
                <p>
                يمكن إلغاء الطلب في أي وقت قبل استلام التأكيد، وأما إذا تم تلقي البريد الإلكتروني لتأكيد الطلب؛ فإنه يتم معالجته على الفور للشحن؛ وبذلك لا يمكن إلغاؤه، ومع ذلك سنبذل قصارى جهدنا لاستيعاب إلغاء الطلبات، ولكن إذا لم نتمكن من القيام بذلك لأي سبب، يمكنك إعادة المنتج وفق سياسة الإرجاع الخاصة بنا أدناه.
                </p>
                 
                <h2 className="subtitle">سياسة الإرجاع</h2>
                <p>
                أجزاء شركة غيار قابلة للإرجاع شريطة عدم تثبيتها أو تفكيكها أو تركيبها أو تعديلها أو إتلافها، وإعادتها خلال 3 أيام من استلام الطلب، ويجب أن تكون جميع العائدات المصرح بها غير مستخدمة وفي حالتها الأصلية؛ بما في ذلك جميع العناصر والمكونات التي تم تضمينها في الحزمة الأصلية، وأما الأجزاء التي يتم إرجاعها كأجزاء خاطئة أو معيبة فإنها تتم وفق إجراءات الإرجاع أدناه خلال 7 أيام لجميع المنتجات. ملاحظة: الأجزاء الخطيرة والكهربائية مثل وحدة التحكم في المحرك والوسادة الهوائية غير قابلة للإرجاع إذا تم توصيلها أو استعمالها لأي سبب.
                </p>
                
                
                <h2 className="subtitle">إجراءات الإرجاع</h2>
                <ul>
                    <li>
                    تحدث إلى أحد متخصصي دعم العملاء لدينا على الرقم ( 800442522 ) أو من خلال خاصية المحادثة الفورية على موقع الويب www.ghayar.ae أو التطبيق الذكي الخاص بنا.
                    </li>
                    <li>
                    سنقدم لك تفويض الإرجاع (RMA)، وستتمكن من إجراء العملية بعد إدخال رقم الشحنة في حسابك لدينا في موقع الويب www.ghayar.ae أو التطبيق الذكي الخاص بنا.
                    </li>
                    <li>
                    ستتلقى بريد الكتروني بتأكيد الموافقة على عملية الإرجاع.
                    </li>
                    <li>
                    قم بتضمين العنصر في العبوة الأصلية (غير التالفة).
                    </li>
                    <li>
                    عنصر إذا خالف سياسة الإرجاع أعلاه أو أن العبوة الأصلية تالفة، وسيتم استلام العنصر من نفس موقع التسليم أو في مكان قريب منه بما لا يزيد 3 كيلو متر، وأما إذا كان في موقع آخر فيتحمل العميل تكلفة الشحن دون النظر في سبب الإرجاع. *** يرجى ملاحظة أن العميل مسؤول عن جميع تكاليف الشحن المرتجعة ما لم يكن الإرجاع ناتج عن عيب مصنعي أو خطأ من شركة غيار.
                    </li>
                    <li>
                    استرداد المبلغ.
                    </li>
                    <li>
                    عادةً ما تتم معالجة المبالغ المستردة وتقييدها في غضون 24-48 ساعة عمل بعد أن نستلم العنصر ونقوم بفحصه، وسيتم إضافة المبلغ المسترد إلى طريقة الدفع التي استخدمتها لشراء العنصر الذي طلبته، وستتلقى رسالة إلكترونية في اليوم الذي تتم فيه معالجة الاسترداد، وإذا تم الشراء باستخدام بطاقة خصم أو بطاقة ائتمان، فسيتم خصم رسوم المعاملات المصرفية من العميل وفقًا لسياسة البنك، وقد يستغرق الأمر من يوم إلى سبعة أيام عمل إضافية حتى تعكس مؤسستك المالية الرصيد المستحق على حسابك.
                    ملاحظة: شاهد فيديو طريقة الإرجاع لدينا
                    </li>
                   
                </ul>
                <h2 className="subtitle">القانون الحاكم / الولاية القضائية</h2>
               <p>تخضع مضمون هذه السياسة لقوانين دولة الإمارات العربية المتحدة إمارة دبي، بغض النظر عن الاختيار أو تضارب مبادئ القانون، وبهذا فأنت توافق على الولاية القضائية لدولة الإمارات العربية المتحدة لحل أي نزاع أو مطالبة أو خلاف يتعلق بالاتفاقات أو ما ينشأ بشأنها.</p>
                <h2 className="subtitle">اتصل بنا</h2>
                <p>إذا كانت لديك أي أسئلة حول السياسة أعلاه التي لم تتم الإجابة عليها، فيرجى الاتصال بنا بأي من الوسائل التالية:</p>

                <ul>
                    <li>الاتصال بنا عبر هاتف الشركة: 97142551383 +</li>
                    <li>أو الهاتف المجاني :800442522</li>
                    <li>أو الموبايل: 97155 760 0255+</li>
                    <li>مراسلتنا عبر البريد الإلكتروني:contact@ghayar.ae</li>
                </ul>
            </div>:
            <div className="container">
                <h2 className="subtitle">Cancelation Policy</h2>
                <p>
                    An order can be cancelled any time before receiving confirmation. However, when the confirming e-mail is received, the order will be processed for delivery, and hence cannot be cancelled. Nevertheless, we will do our best to understand order cancellation; otherwise, the product can be returned in accordance with the return policy stated down below.
                </p>
                 
                <h2 className="subtitle">Return Policy</h2>
                <p>
                    Ghayar spare parts are returnable in the event that they have not been fixed, disassembled, adjusted or damaged within 3 days of receiving the order. However, the returned items should be unused and in their original packing, including all elements and components contained in the original packing. In case of defect on the parts, they will be subject to return procedures within 7 days through which evaluation takes place.
                </p>
                <p>
                    PS: Electrical or Hazardous material like Air Bag/ECM etc., parts are unreturnable if connected or used for whatever reason.
                </p>
                <h2 className="subtitle">Return Procedures</h2>
                <ul>
                    <li>
                    Talk to a customer support specialist on 800442522, chat on the web: www.ghayar.ae or the respective app.
                    </li>
                    <li>
                    You will receive return authorization (RA) after entering shipment number in your account on the web: www.ghayar.ae or the respective app
                    </li>
                    <li>
                    Then, you will receive an email, confirming acceptance of return process
                    </li>
                    <li>
                    Put the item in the original packing (non-damaged).
                    </li>
                    <li>
                    The delivery company will coordinate with you to receive the item, if it is still in the original packing and does not violate the return policy stated above. The item shall be picked from the delivery location or anywhere in the vicinity no more than 3 kilometers far. Otherwise, the customer shall bear the shipment cost regardless of the return reason.
                    Please bear in mind that returned item(s) shipment cost shall be on the customer in cases of no manufacturing defect or a fault from the part of Ghayar Company.
                    </li>
                    <li>
                    Refund: refund is processed within 24-48 hours following the receipt of item and check thereof. Refunded amount shall be added to the payment method followed while purchasing, along with a confirming message. Purchases done by credit cards may take from one to seven working days to enable your financial institution to reflect the refunded amount on your account. The bank transaction charge will be deducted from customer according to the bank policy.
                    </li>
                    
                </ul>
                <h2 className="subtitle">Governing Law/Jurisdiction</h2>
                <p>The content of the policy herein is subject to the United Arab Emirates (UAE) laws, Emirate of Dubai, regardless of choice or conflicting principles of law. The customer shall thus agree to turn to the UAE Jurisdiction in case of any conflict, claim or dispute with regard to agreements and whatever emanating therefrom.</p>
                <h2 className="subtitle">Contact Us</h2>
                <p>In case of queries not addressed hereinabove, please contact us through the following means.</p>

                <ul>
                <li>Company Tel: +97142551383</li>
                <li>Toll-free: 800442522</li>
                <li>Mob: +97155 760 0255</li>
                <li>Email: contact@ghayar.ae</li>
                </ul>
            </div>
          
            }
          </div>
         
        </main>
          )
}

export default Cancelation;