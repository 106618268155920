import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
import { Rating } from '@smastrom/react-rating'
import axios from "axios";
import Swal from "sweetalert2";
import '@smastrom/react-rating/style.css'

const Reviews = ({title, partNumber}) => {
    const [recents, setRecents] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const {gdcURL, apiURL} = useContext(ConfigContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [review, setReview] = useState('');
    const [rating, setRating] = useState(0)
    const [yesiwould, setYesiwould] = useState(0);
    const [reviewSent, setReviewSent] = useState(false);
    const [sendingStatus, setSendingStatus] = useState(false);
    const AddReview = async() => {
        setSendingStatus(true);
        const headers = {
            Accept: "application/json",
            "content-type": "application/json",
          };
        var formdata = {
            customer_id: localStorage.getItem('customer_id')?Number(localStorage.getItem('customer_id')): 0,
            name: name,
            email: email,
            review: review,
            rating: rating,
            yes_iwould: yesiwould,
            part_number: partNumber
        }
        console.log("formdata", formdata)
        const { data } = await axios.post( `${apiURL}/Parts/AddReview`, formdata );
        
        if (data.success) {
            // setReviewSent(true);
            Swal.fire({
                title: 'Success',
                text: 'Review has been sent',
                icon: 'success',
                confirmButtonText: 'Okay'
              })
              setName("");
              setEmail("");
              setReview("");
              setRating(0);
              setReviewSent(true);
        }else{
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Okay'
              })
        }
        setSendingStatus(false);

    }
    const BtnDisable = () => {
        if(!name || !email || !review || rating===0){
            return true;
        }
        if(sendingStatus){
            return sendingStatus;
        }
    }
    return (
        <div>
        {
            reviewSent?<div className="alert alert-success text-center" style={{textAlign: 'center'}}>
            Your review has been submitted
            </div>:
        <div className="container" dir={dir} style={{textAlign: dir ==='rtl'?'right': 'left'}}>
            <h5>{title}</h5>
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Rating style={{ maxWidth: 250 }} value={rating} onChange={setRating} />
                </div>
                <div className="col-md-6">
                   
                     <input className="form-control" placeholder={t('name')} onChange={(e) => setName(e.target.value)}/>
                    
                </div>
                <div className="col-md-6">
                    <input className="form-control" placeholder={t('email')} onChange={(e) => setEmail(e.target.value)}/>
                </div>
            
                <div className="col-md-12 mb-1">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={(e) => setYesiwould(e.target.checked?1:0)} value="" id="flexCheckDefault" />
                        <label className="form-check-label ml-4 mr-4" htmlFor="flexCheckDefault" > {t('yes_i_would_like')}
                        </label>
                    </div>
                </div>
                
                <div className="col-md-12">
                    <div className="form-group">
                    
                        

                        <textarea className="form-control" placeholder={t('write_review')} onChange={(e) => setReview(e.target.value)}>

                        </textarea>
                    </div>
                </div>
            </div>
            <button disabled={BtnDisable()} className="btn btn-primary" type="button" onClick={AddReview}>{t('submit_a_review')}</button>
        </div>
        }
        </div>
    )
}

export default Reviews;


