import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { ConfigContext } from "../../Context/ConfigContext";
import AccountSiderBar from "./AccountSideBar";
import InvoicePDF from "../../Components/Invoice/InvoicePDF";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import QRCode from 'qrcode';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

const OrderDetails = () => {
  
  const {apiURL, token, lang} = useContext(ConfigContext);
  const {order_uid} = useParams();
  const [orderInfo, setOrderInfo] = useState([]);
  const [address, setAddress] = useState([]);
  const [details, setDetails] = useState([]);
  const [items, setItems] = useState([]);
  const [success, setSuccess] = useState(true);
  const { t } = useTranslation();
  const dir = localStorage.getItem('dir');
  const [qrCodeURL, setQrCodeURL] = useState('');
    useEffect(() => {
        QRCode.toDataURL('https://ghayar.com')
        .then(url => {
            setQrCodeURL(url);
            console.log(url)
        })
        .catch(err => {
            console.error(err)
        })

    }, [])
  
  useEffect(() => {
    OrderInfo();
  }, [])
  
  const OrderInfo = async () => {
    try {
      const response = await axios.get(`${apiURL}/Account/GetMyOrderInfo`, {
        params: {
          lang: lang,
          customer_id: localStorage.getItem("customer_id") || 1,
          order_uid: order_uid,
        },
        headers: {
          token: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log(token, response.data);
      var address = JSON.parse(response.data.data.details.address_json);
      setOrderInfo(response.data.data)
      setDetails(response.data.data.details);
      setItems(response.data.data.items);
      setSuccess(response.data.success);
      setAddress(address)
    } catch (error) {
      console.log("error", error);
    }
  };

  const { downloadPDF, printPDF, getEmailPDF } = InvoicePDF({ orderInfo, details, items, address,order_uid });

  const handleDownloadInvoice = async () => {
    try {
      await downloadPDF();
    } catch (error) {
      console.error('Error downloading PDF:', error);
      toast.error(t("error_downloading_invoice"));
    }
  };

  const handlePrintInvoice = async () => {
    try {
      await printPDF();
    } catch (error) {
      console.error('Error printing PDF:', error);
      toast.error(t("error_printing_invoice"));
    }
  };

  const handleMailInvoice = async () => {
    try {
      const pdfContent = await getEmailPDF();
      const response = await axios.post(`${apiURL}/Account/SendInvoiceEmail`, {
        order_uid: orderInfo.order_uid,
        customer_id: localStorage.getItem("customer_id") || 1,
        pdf_content: pdfContent,
      }, {
        headers: {
          token: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.data.success) {
        toast.success(t("invoice_sent_successfully"));
      } else {
        toast.error(t("failed_to_send_invoice"));
      }
    } catch (error) {
      console.error("Error sending invoice:", error);
      toast.error(t("error_sending_invoice"));
    }
  };

  return (
    <main>
      <div className="page-header">
        <div className="container d-flex flex-column align-items-center">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb" dir={dir}>
                <li className="breadcrumb-item">
                  <NavLink to={"/"}>{t("home")}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/MyAccount`}>{t("my_account")}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/MyAccount/Orders`}>{t("orders")}</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t("order_details")}
                </li>
              </ol>
            </div>
          </nav>
          <h1>{t("order_details")}</h1>
        </div>
      </div>
      <div className="container account-container custom-account-container" dir={dir}>
        <div className="row">
          <AccountSiderBar />
          <div className="col-lg-9 order-lg-last order-1 tab-content">
            <div
              className="tab-pane fade show active"
              id="address"
              role="tabpanel">
              <div className="order-content">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="account-sub-title d-none d-md-block">
                      <i className="sicon-social-dropbox align-middle mr-3" />
                      
                      {t("order_number", {value: ":"+order_uid})}
                    </h3>
                  </div>
                  <div className="col-md-6 align-bottom d-flex justify-content-right">
                    <div className="align-bottom d-flex justify-content-right" style={{paddingTop: '10px'}}>
                      <span style={{cursor: 'pointer'}} className={`nav-link`} onClick={handleDownloadInvoice}><i className="sicon-cloud-download"></i> {t("download_invoice")}</span>
                      <span style={{cursor: 'pointer'}} className={`nav-link`} onClick={handlePrintInvoice}><i className="sicon-printer"></i> {t("print_invoice")}</span>
                      <span style={{cursor: 'pointer'}} className={`nav-link`} onClick={handleMailInvoice}><i className="sicon-envelope"></i> {t("mail_invoice")}</span>
                    </div>
                  </div>
                </div>
                
                <div className="order-table-container text-center">
                  <div className="order-summary">
                    <table>
                      <tr>
                        <td
                          className="text-left align-top"
                          style={{ width: "40%" }}>
                          <h5>{t("shipping_address")}</h5>
                          <b>
                            {address.first_name} {address.last_name}
                          </b>
                          <p
                            className="xw-50"
                            style={{ fontFamily: "arial" }}>
                            <i>
                              {" "}
                              {address.complete_address} <br />
                              {address.Street} {address.city} -{" "}
                              {address.country} <br />{" "}
                              {address.mobile_number_1} <br />{" "}
                              {address.contact_email}{" "}
                            </i>
                          </p>
                        </td>
                        <td
                          className="text-left align-top"
                          style={{ width: "35%", textTransform: 'uppercase' }}>
                          <h5>{t("payment_method")}</h5>
                          {details.payment_method === "COD" ? (
                            <b>{t("cash_on_delivery")}</b>
                          ) : (
                            <b>{details.payment_method}</b>
                          )}
                          <img src={qrCodeURL} alt="QR Code" />
                        </td>
                        <td
                          className="text-left align-top"
                          style={{ width: "35%" }}>
                          <div className="align-right">
                            <h5>{t("order_summary")}</h5>
                            <table>
                              <tr>
                                <td>{t("sub_total")}</td>
                                <td>: {details.sub_total}</td>
                              </tr>
                              <tr>
                                <td>{t("shipping")}</td>
                                <td>
                                  :{" "}
                                  {Number(details.shipping_charges)
                                    ? details.shipping_charges
                                    : "00.0"}
                                </td>
                              </tr>
                              <tr>
                                <td>{t("total")}</td>
                                <td>
                                  :{" "}
                                  {Number(details.shipping_charges)
                                    ? details.shipping_charges +
                                      details.sub_total
                                    : details.sub_total}
                                </td>
                              </tr>
                              <tr>
                                <td>{t("promotion_applied")}</td>
                                <td>: {details.discounted_amount}</td>
                              </tr>
                              <tr>
                                <th style={{ color: "#000" }}>{t("grand_total")}</th>
                                <th>: {details.grand_total}</th>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="order-summary">
                    <table className="table table-cart">
                      <thead>
                        <tr>
                          
                          <th className="product-col">{t(`product`)}</th>
                          <th className="price-col">{t(`price`)}</th>
                          <th className="qty-col">{t(`quantity`)}</th>
                          <th className="text-right">{t(`sub_total`)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => {
                          return (
                            <tr className="product-row" key={item.order_item_id}>
                              
                              <td className="product-col text-left">
                                <h5 className="product-title">
                                {item.item_title} - {item.item_number} <br />
                                <NavLink to={item.item_url}><button style={{padding: '0.2em 0.5em',fontSize: '1.1rem'}} className="btn btn-primary btn-icon-left btn-rounded btn-sm">
                                  <i className="fa fa-sync-alt"></i> {" "}{t(`buy_it_again`)}{" "}</button></NavLink>
                                </h5>
                              </td>
                              <td>{item.item_display_price}</td>
                              <td>
                                <div className="product-single-qty">
                                  <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                    <span className="input-group-btn input-group-prepend">
                                    {item.item_qty}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                <span className="subtotal-price">{item.item_qty * item.item_price}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
  
}

export default OrderDetails;
