import React, { Component, useContext } from "react";
import Slider from "./Slider";
import FeaturedProducts from "../../Components/Home/FeaturedProducts/FeaturedProducts";
import InfoBox from "../../Components/Home/InfoBox/InfoBox";
import NewArrivals from "../../Components/Home/NewArrivals/NewArrivals";
import Promotion from "../../Components/Home/Promotions/Promotion";
import CustomerSupport from "../../Components/Home/CustomerSupport/CustomerSupport";
import LatestNews from "../../Components/Home/LatestNews/LatestNews";
import SelectedCarView from "../../Components/Home/SelectedCarView";
import { ConfigContext } from "../../Context/ConfigContext";
import CarSelectionOption from "../../Components/CarSelectionOption";
import Offers from "./Offers";
import CarSearchBox from "../../Layout/CarSeachBox";
import Suppliers from "./Suppliers";
import Makes from "./Makes";
import RecentViews from "./RecentViews";
import Offer4x4Section from "./4x4Section";
import Sections from "../SpareParts/Catelog/Sections";
import useIsMobile from "../../Helper/useIsMobile";
import MobSection from "../SpareParts/Catelog/MobSection";
import { Helmet } from "react-helmet";
import SocialMediaFooter from "./SocialMediaFooter";

// import Slider from "./Slider";
// import BelowSliderHighlights from "./BelowSliderHighlights";

const Home = () => {
    const {CarStatus, resizeState } = useContext(ConfigContext);
    const isMobile = useIsMobile();
    return (
        <div>
            <Helmet>
                <title>Ghayar LLC</title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="csrf-token" content="VeV893Poy3i5QHyNChCCGZVxaFTw34sv7Rd61d3X" />
                <meta name="robots" content="" />
                <meta name="description" content="Ghayar deals in e-commerce by providing spare parts to all types of vehicles with quick and safe delivery service. Besides its commitment to global policies guaranteeing the spare parts and return flexibility to achieve total customer satisfaction." />
                <meta name="keywords" content="parts in UAE, Car Parts, Spareparts,Spareparts in UAE,Dubai Car Parts, parts in dubai,parts in abu dhabi, parts in UAE,parts in United Arab Emirates, parts in KSA," />
                <meta property="og:image" content="https://Ghayar.com/assets/images/Logo/car7_transparent_powered_by.png" />
                <meta property="og:title" content="Ghayar LLC" />
                <meta property="og:description" content="Ghayar deals in e-commerce by providing spare parts to all types of vehicles with quick and safe delivery service. Besides its commitment to global policies guaranteeing the spare parts and return flexibility to achieve total customer satisfaction." />
                <meta property="og:url" content="https://Ghayar.com/" />
                <meta name="google-site-verification" content="sqbHPE0JL6KdK0P-oI0unVHXTA2jxGM9cebO3qfkyqE" />
                <meta property="twitter:title" content="Ghayar LLC" />
                <meta property="twitter:description" content="Ghayar deals in e-commerce by providing spare parts to all types of vehicles with quick and safe delivery service. Besides its commitment to global policies guaranteeing the spare parts and return flexibility to achieve total customer satisfaction." />
                <meta property="twitter:url" content="https://Ghayar.com/" />
            </Helmet>
            {/* {CarStatus === false && isMobile ? <CarSelectionOption></CarSelectionOption> : <div></div>}
            {CarStatus === true && isMobile ? <SelectedCarView /> : <div></div>}  */}
            
            <Slider />
            <InfoBox />
            <section className="featured-products-section" >
            <RecentViews />
            </section>
            
            {/* <Offer4x4Section /> */}
            {isMobile ? <MobSection/>: <Sections />}
            <CustomerSupport />

            <section className="featured-products-section" >
            <Offers />
            </section>

            <Suppliers /> 
            
            <SocialMediaFooter />
        
            {/* <BelowSliderHighlights /> */}
        </div>
    );
    
}
export default Home;